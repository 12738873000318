import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { BreadcrumbsService } from '../breadcrumbs/breadcrumbs.service';
import { ToolbarService } from '../toolbar/toolbar.service';
import { ToolbarAction } from '../toolbar/ToolbarAction';
import { BreadcrumbsComponent } from '../breadcrumbs/breadcrumbs.component';
import { EventsService } from 'src/app/core/services/events/events.service';
import { AppInjectorService } from 'src/app/core/services/injector/app-injector.service';
import { BreadcrumbEvents } from '../breadcrumbs/breadcrumb-events';

@Component({
  selector: 'app-page',
  templateUrl: './page.component.html',
  styleUrls: ['./page.component.scss']
})
export class PageComponent implements OnInit, AfterViewInit {

  @ViewChild('breadcrumb') breadcrumb?: BreadcrumbsComponent;
  protected eventsService: EventsService;
  protected breadcrumbsService: BreadcrumbsService;
  protected toolbarService: ToolbarService;

  constructor() {
    this.eventsService = AppInjectorService.injector.get(EventsService);
    this.breadcrumbsService = AppInjectorService.injector.get(BreadcrumbsService);
    this.toolbarService = AppInjectorService.injector.get(ToolbarService);
    
    this.toolbarService.reset();
    this.breadcrumbsService.reset();
  }

  ngAfterViewInit(): void {
    this.eventsService.emit(BreadcrumbEvents.Reload);
  }

  ngOnInit(): void {
  }

  protected addAction(action: ToolbarAction) {
    this.toolbarService.addAction(action)
  }


}


