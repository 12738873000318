import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AuthenticationService } from 'src/app/auth/authentication.service';
import { Person } from '../person';
import { Member } from '../../members/member';

@Component({
  selector: 'app-person-communication-form',
  templateUrl: './person-communication-form.component.html',
  styleUrls: ['./person-communication-form.component.scss']
})
export class PersonCommunicationFormComponent {
  @Input() public person: Person = new Person();
  @Output() public personChange: EventEmitter<Person> = new EventEmitter<Person>();

  constructor(
    public authenticationService: AuthenticationService
  ) { }
}
