<div class="row">
    <div class="col-6">
        <app-grid 
        #grid 
        [columns]=columns 
        [actions]="toolbarActions" 
        [service]="service" 
        (delete)="onDelete($event)"
        (edit)="onEdit($event)"
        ></app-grid>
    </div>
</div>

<app-modal #modal title="Mileage expenses" (confirm)="onConfirmModal(modal.data)">
    <ng-template modalContent>
        <kendo-label text="{{'Amount' | translate}}"></kendo-label>
        <kendo-numerictextbox class="mb-3" [(ngModel)]="modal.data.amount" [decimals]="2"></kendo-numerictextbox>
        <kendo-label text="{{'Start date' | translate}}"></kendo-label>
        <kendo-datepicker class="mb-3" [(value)]="modal.data.startDate"></kendo-datepicker>
        <kendo-label text="{{'End date' | translate}}"></kendo-label>
        <kendo-datepicker [(value)]="modal.data.endDate"></kendo-datepicker>
    </ng-template>
</app-modal>