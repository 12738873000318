import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BreadcrumbsService {
  public parser: any;

  constructor(
    private router: Router
  ) {
    this.reset();
  }

  reset() {
    this.parser = (segment: string) => {
      return {
        text: segment.charAt(0).toUpperCase() + segment.slice(1),
        click: (e: any) => { this.router.navigateByUrl('admin/' + segment) }
      }
    };
  }

  setSegmentParser(e: any) {
    this.parser = e;
  }

  public getSegmentName(segment: string) {
    return this.parser(segment);
  }

  private breadcrumbsUpdater = new BehaviorSubject<boolean>(true);
  public breadcrumbsUpdater$ = this.breadcrumbsUpdater.asObservable();
  public updateBreadcrumbs(): void {    
    this.breadcrumbsUpdater.next(true);
  }
}
