import { Component, Input } from '@angular/core';

@Component({
  selector: 'user-type-badge',
  templateUrl: './user-type-badge.component.html',
  styleUrls: ['./user-type-badge.component.scss']
})
export class UserTypeBadgeComponent {
  @Input() color: string | undefined;
  @Input() background: string | undefined;
  @Input() border: string | undefined;

  @Input() text: string = '';
}
