import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { State } from '@progress/kendo-data-query';
import { Observable } from 'rxjs';
import { AppApiService } from 'src/app/app-api.service';
import { AppNotificationService } from 'src/app/app-notification.service';

@Injectable({
  providedIn: 'root'
})
export class MembersService extends AppApiService {
  public loading: boolean = false;
  public url: string | null = 'cesew/members';

  constructor(
    protected http: HttpClient,
    protected notificationService?: AppNotificationService
  ) {
    super(http, notificationService);
  }

  exportMembershipHistory(memberId: number): Observable<HttpResponse<Blob>> {
    return this.downloadFile(this.url + '/exportMembershipHistory/' + memberId);
  }

  getMembersList(): Promise<Object | undefined> {
    return this.get(null, this.url + '?list=1');
  }

}
