import { Injectable } from '@angular/core';
import { Router, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { Observable, lastValueFrom, of } from 'rxjs';
import { AppointmentService } from './appointment.service';

@Injectable({
  providedIn: 'root'
})
export class AppointmentResolver  {
  public element_id: string | number | undefined;

  constructor(
    private service: AppointmentService,
  ) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
    this.element_id = route.params.id;
    return this.service.find(this.element_id);
  }
}
