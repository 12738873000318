<ng-container *ngIf="person">
    <div class="row">
        <p class="text-end m-0">{{'Last edit' | translate}} : {{ person.updated_at | date:'short'}}</p>
    </div>
    <div class="row mt-2">
        <div *ngIf="person.isMember" class="col-2">
            <user-type-badge text="Member"></user-type-badge>
        </div>
        <div class="col-2">
            <user-type-badge
                text="Communications"
                background="white"
                color="primary"
                border="primary"
            ></user-type-badge>
        </div>
        <div class="col"></div>
        <div
            class="col-2"
            *ngIf="person.deffered"
        >
            <user-type-badge
                text="Deffered"
                background="dark"
                color="white"
                border="dark"
            ></user-type-badge>
        </div>
        <div
            class="col-2"
            *ngIf="person.microsoft_id"
        >
            <user-type-badge
                text="Managed"
                background="white"
                color="success"
                border="success"
            ></user-type-badge>
        </div>
    </div>

    <div class="row">
        <div class="col-sm-2">
            <kendo-label text="{{'Title' | translate}}"></kendo-label>
            <ng-container *ngIf="authenticationService.hasOnePermission(['members.update']);else titleDisabled">
                <kendo-dropdownlist
                    [(ngModel)]="person.title_id"
                    [data]="titlesHelper.datas"
                    [valuePrimitive]="true"
                    textField="displayName"
                    valueField="id"
                ></kendo-dropdownlist>
            </ng-container>
            <ng-template #titleDisabled>
                <kendo-textbox
                    [ngModel]="person.person_title?.displayName"
                    [disabled]="true"
                ></kendo-textbox>
            </ng-template>
        </div>
        <div class="col-sm-2">
            <kendo-label text="{{'Gender' | translate}}"></kendo-label>
            <ng-container *ngIf="authenticationService.hasOnePermission(['members.update']);else genderDisabled">
                <kendo-dropdownlist
                    [(ngModel)]="person.gender_id"
                    [data]="gendersHelper.datas"
                    [valuePrimitive]="true"
                    textField="displayName"
                    valueField="id"
                ></kendo-dropdownlist>
            </ng-container>
            <ng-template #genderDisabled>
                <kendo-textbox
                    [ngModel]="person.person_gender?.displayName"
                    [disabled]="true"
                ></kendo-textbox>
            </ng-template>
        </div>
        <div class="col-sm-4">
            <kendo-label text="{{'Lastname' | translate}}"></kendo-label>
            <kendo-textbox
                [(ngModel)]="person.lastname"
                [disabled]="!authenticationService.hasOnePermission(['members.update'])"
            ></kendo-textbox>
        </div>
        <div class="col-sm-4">
            <kendo-label text="{{'Firstname' | translate}}"></kendo-label>
            <kendo-textbox
                [(ngModel)]="person.firstname"
                [disabled]="!authenticationService.hasOnePermission(['members.update'])"
            ></kendo-textbox>
        </div>
        <!-- <div class="col-sm-2" *ngIf="member && member.entry_date">
            <kendo-label text="{{'Entry date' | translate}}"></kendo-label>
            <kendo-datepicker
                [value]="getDatePickerValue(member.entry_date)"
                (valueChange)="setEntryDateValue($event)"
                [disabled]="!authenticationService.hasOnePermission(['members.update'])"
            ></kendo-datepicker>
        </div> -->
    </div>
    <div class="row mt-3">

        <div class="col-sm-3">
            <fieldset>
                <legend>{{'Company' | translate}}</legend>

                <div class="py-2">
                    <kendo-label text="{{'Company' | translate}}"></kendo-label>
                    <ng-container *ngIf="authenticationService.hasOnePermission(['members.update']);else companyDisabled">
                        <kendo-combobox
                            [data]="companyHelper.filteredDatas"
                            [allowCustom]="true"
                            [valueNormalizer]="companyNormalizer"
                            [filterable]="true"
                            name="company"
                            [(ngModel)]="person.person_company"
                            (ngModelChange)="person.company_id = $event?.id || null"
                            textField="displayName.fr"
                            (filterChange)="companyHelper.handleFilter('displayName.fr', $event)"
                            valueField="id"
                        ></kendo-combobox>
                    </ng-container>
                    <ng-template #companyDisabled>
                        <kendo-textbox
                            [(ngModel)]="person.person_company?.displayName.fr"
                            [disabled]="true"
                        ></kendo-textbox>
                    </ng-template>
                </div>

                <div class="py-2">
                    <kendo-label text="{{'Company type' | translate}}"></kendo-label>
                    <ng-container *ngIf="authenticationService.hasOnePermission(['members.update']);else companyTypeDisabled">
                        <kendo-combobox
                            [data]="companyTypesHelper.filteredDatas"
                            [filterable]="true"
                            name="companyType"
                            [(ngModel)]="person.person_company_type"
                            (ngModelChange)="person.company_type_id = $event?.id || null"
                            textField="displayName.fr"
                            (filterChange)="companyTypesHelper.handleFilter('displayName.fr', $event)"
                            valueField="id"
                        ></kendo-combobox>
                    </ng-container>
                    <ng-template #companyTypeDisabled>
                        <kendo-textbox
                            [(ngModel)]="person.person_company_type?.displayName.fr"
                            [disabled]="true"
                        ></kendo-textbox>
                    </ng-template>
                </div>

                <div class="py-2">
                    <kendo-label text="{{'Tenure' | translate}}"></kendo-label>
                    <ng-container *ngIf="authenticationService.hasOnePermission(['members.update']);else tenureDisabled">
                        <kendo-combobox
                            [data]="tenureHelper.filteredDatas"
                            [allowCustom]="true"
                            [valueNormalizer]="normalizer"
                            [filterable]="true"
                            name="input-custom-tenure"
                            [(ngModel)]="person.person_tenure"
                            (ngModelChange)="person.tenure_id = $event?.id || null"
                            textField="displayName.fr"
                            (filterChange)="tenureHelper.handleFilter('displayName.fr', $event)"
                            valueField="id"
                        ></kendo-combobox>
                    </ng-container>
                    <ng-template #tenureDisabled>
                        <kendo-textbox
                            [(ngModel)]="person.person_tenure?.displayName.fr"
                            [disabled]="true"
                        ></kendo-textbox>
                    </ng-template>
                </div>
            </fieldset>
        </div>

        <div class="col-sm-3">
            <fieldset>
                <legend>{{'Function' | translate}}</legend>

                <div class="py-2">
                    <kendo-label text="{{'Function' | translate}}"></kendo-label>
                    <ng-container *ngIf="authenticationService.hasOnePermission(['members.update']);else companyRoleDisabled">
                        <kendo-combobox
                            [data]="companyRolesHelper.filteredDatas"
                            [allowCustom]="true"
                            [valueNormalizer]="normalizer"
                            [filterable]="true"
                            name="companyRole"
                            [(ngModel)]="person.person_company_role"
                            (ngModelChange)="person.company_role_id = $event?.id || null"
                            textField="displayName.fr"
                            (filterChange)="companyRolesHelper.handleFilter('displayName.fr', $event)"
                            valueField="id"
                        ></kendo-combobox>
                    </ng-container>
                    <ng-template #companyRoleDisabled>
                        <kendo-textbox
                            [(ngModel)]="person.person_company_role?.displayName.fr"
                            [disabled]="true"
                        ></kendo-textbox>
                    </ng-template>
                </div>

                <div class="py-2">
                    <kendo-label text="{{'Function type' | translate}}"></kendo-label>
                    <ng-container *ngIf="authenticationService.hasOnePermission(['members.update']);else companyRoleTypeDisabled">
                        <kendo-combobox
                            [data]="companyRoleTypesHelper.filteredDatas"
                            [filterable]="true"
                            name="companyRoleType"
                            [(ngModel)]="person.person_company_role_type"
                            (ngModelChange)="person.company_role_type_id = $event?.id || null"
                            textField="displayName.fr"
                            (filterChange)="companyRoleTypesHelper.handleFilter('displayName.fr', $event)"
                            valueField="id"
                        ></kendo-combobox>
                    </ng-container>
                    <ng-template #companyRoleTypeDisabled>
                        <kendo-textbox
                            [(ngModel)]="person.person_company_role_type?.displayName.fr"
                            [disabled]="true"
                        ></kendo-textbox>
                    </ng-template>
                </div>
            </fieldset>
        </div>
        <div class="col-sm-3">
            <fieldset>
                <legend>{{'Emails' | translate}}</legend>

                <div class="py-2">
                    <kendo-label text="{{'Primary email' | translate}}"></kendo-label>
                    <kendo-textbox
                        [(ngModel)]="person.primary_email"
                        [disabled]="person.id && person.isMember"
                        [showErrorIcon]="!personService.isValidEmail(person.primary_email)"
                    ></kendo-textbox>
                </div>

                <div class="py-2">
                    <kendo-label text="{{'Secondary email' | translate}}"></kendo-label>
                    <kendo-textbox
                        [(ngModel)]="person.secondary_email"
                        [disabled]="!authenticationService.hasOnePermission(['members.update'])"
                    ></kendo-textbox>
                </div>
            </fieldset>
        </div>

        <div class="col-sm-3">
            <fieldset>
                <legend>{{'Contacts' | translate}}</legend>

                <div class="py-2">
                    <kendo-label text="{{'Pro phone' | translate}}"></kendo-label>
                    <kendo-textbox
                        [(ngModel)]="person.pro_phone"
                        [disabled]="!authenticationService.hasOnePermission(['members.update'])"
                    ></kendo-textbox>
                </div>

                <div class="py-2">
                    <kendo-label text="{{'Private phone' | translate}}"></kendo-label>
                    <kendo-textbox
                        [(ngModel)]="person.private_phone"
                        [disabled]="!authenticationService.hasOnePermission(['members.update'])"
                    ></kendo-textbox>
                </div>

                <div class="py-2">
                    <kendo-label text="{{'GSM' | translate}}"></kendo-label>
                    <kendo-textbox
                        [(ngModel)]="person.private_gsm"
                        [disabled]="!authenticationService.hasOnePermission(['members.update'])"
                    ></kendo-textbox>
                </div>
            </fieldset>
        </div>
    </div>
</ng-container>
