import { Component, ViewChild } from '@angular/core';
import { PageComponent } from 'src/app/ui/page/page.component';
import { MileageExpensesDetailsComponent } from './mileage-expenses-details/mileage-expenses-details.component';

@Component({
  selector: 'app-configuration',
  templateUrl: './configuration.component.html',
  styleUrls: ['./configuration.component.scss']
})
export class ConfigurationComponent extends PageComponent{
  
  @ViewChild('mileageExpensesDetail') mileageExpensesDetail?: MileageExpensesDetailsComponent;
  
  constructor(){
    super();
  }

  ngOnInit(): void {
    this.addAction({ code: 'save', text: 'Save', icon: 'save', click: () => this.onSaveButtonClicked(), btnStyle: 'outline-primary' });
  }

  
  public async onSaveButtonClicked(): Promise<void> {
    this.mileageExpensesDetail?.save();
  }

}
