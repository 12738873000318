import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Committee } from 'src/app/admin/committees/committee.modele';
import { Person } from 'src/app/admin/people/person';
import { AppApiService } from 'src/app/app-api.service';
import { AppNotificationService } from 'src/app/app-notification.service';

@Injectable({
  providedIn: 'root'
})
export class AadService extends AppApiService {
  public url: string | null = '';

  constructor(
    protected http: HttpClient,
    protected notificationService?: AppNotificationService
  ) {
    super(http, notificationService);
  }

  // Groups
  // ------
  public async addMemberToGroup(committee: Committee, person: Person): Promise<void> {
    if (committee.microsoft_id === null || person.microsoft_id === null) return;
    return await this.post(null, `aad/group/${committee.id}/member/${person.id}`);
  }

  public async removeMemberFromGroup(committee: Committee, person: Person): Promise<void> {    
    if (committee.microsoft_id === null || person.microsoft_id === null) return;
    return await this.delete(undefined, `aad/group/${committee.id}/member/${person.id}`);
  }
  public async isMember(committee: Committee, person: Person): Promise<boolean> {
    if (committee.microsoft_id === null || person.microsoft_id === null) return false;
    return await this.get(null, `aad/group/${committee.id}/member/${person.id}`);
  }

  // Users
  // -----
  public async addMember(person: Person): Promise<any> {
    return await this.post(undefined, `aad/user/sendaadinvitation/${person.id}`);
  }

  public async removeMember(person: Person): Promise<void> {
    if (person.microsoft_id === null) return;
    return await this.delete(undefined, `aad/user/${person.id}`);
  }
  public async deleteMember(person: Person): Promise<void> {
    if (person.microsoft_id === null) return;
    return await this.delete(undefined, `aad/user/${person.id}/force`);
  }

  public async updateMember(person: Person): Promise<void> {
    if (person.microsoft_id === null) return;
    return await this.patch(undefined, `aad/user/${person.id}`);
  }
}
