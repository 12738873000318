import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppApiService } from 'src/app/app-api.service';
import { AppNotificationService } from 'src/app/app-notification.service';
import { AuthenticationService } from 'src/app/auth/authentication.service';

@Injectable({
  providedIn: 'root'
})
export class AppointmentService extends AppApiService {
  public url: string | null = 'cesew/appointment';

  constructor(
    protected http: HttpClient,
    protected notificationService: AppNotificationService,
    private appApiService: AppApiService,
    private authenticationService: AuthenticationService
  ) {
    super(http, notificationService);
  }

  public getUserAppointments(startDate = '') {
    return this.appApiService.get(null, `aad/user/events?startDate=${startDate}`);
  }

  public importAppointments(iCalUId: string) {
    return this.appApiService.get(null, 'aad/user/events/' + iCalUId + '/import');
  }

  /**
   * @deprecated use synchronizeAppointment instead
   */
  public updateAppointment(id: string | number | undefined) {
    return this.appApiService.post(null, 'aad/user/events/' + id + '/update');
  }

  public synchronizeAppointment(id: string | number | undefined, data:any) {
    return this.appApiService.post(data, 'cesew/appointment/' + id + '/synchronize',);
  }

  public getAttendees(id: string | number | undefined) {
    return this.appApiService.get(null, 'aad/user/events/' + id + '/attendees');
  }

  public updateAttendees(id: string | number | undefined, person_id: string | number | undefined, committee_role_id: string | number | undefined) {
    return this.appApiService.post({ committee_role: committee_role_id }, 'aad/user/' + this.authenticationService.user?.id + '/events/' + id + '/attendees/' + person_id);
  }

  public exportAttendees(id: string | number) {
    return this.appApiService.downloadFile('cesew/appointment/' + id + '/exportAttendance');
  }
}
