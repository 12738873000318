import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { CompositeFilterDescriptor } from '@progress/kendo-data-query';
import { PageComponent } from 'src/app/ui/page/page.component';
import { HistoryCommitteesMembersService } from './history-committees-members.service';
import { AppGridComponent } from 'src/app/ui/grid/grid.component';
import { ModalComponent } from 'src/app/ui/modal/modal.component';
import { AppNotificationService } from 'src/app/app-notification.service';

@Component({
  selector: 'app-history-committees-members-grid',
  templateUrl: './history-committees-members-grid.component.html',
  styleUrls: ['./history-committees-members-grid.component.scss']
})
export class HistoryCommitteesMembersGridComponent extends PageComponent implements OnInit {
  @Input() public committees_id: number = 0;

  @ViewChild('historyCommitteeMembersGrid') historyCommitteeMembersGrid!: AppGridComponent;
  @ViewChild("editCommitteeMemberModal") editCommitteeMemberModal: ModalComponent = new ModalComponent();

  constructor(
    public historyCommitteesMembersService: HistoryCommitteesMembersService,
    private notificationService: AppNotificationService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.historyCommitteeMembersFilters = {
      logic: 'and',
      filters: [
        {
          field: 'committee_id',
          operator: 'eq',
          value: this.committees_id,
        },
      ],
    };

    this.reloadGrid();
  }

  public historyCommitteeMembersFilters!: CompositeFilterDescriptor;
  public historyCommitteeMembersColumns: any[] = [
    {
      field: "member.person.person_gender.displayName", title: "Gender", joins: [
        { sequence: 1, tablename: "members", foreignKey: "history_committees_members.member_id" },
        { sequence: 2, tablename: "people", foreignKey: "members.person_id" },
        { sequence: 2, tablename: "person_genders", foreignKey: "people.gender_id" }
      ]
    },
    {
      field: "member.person.lastname", title: "Lastname", joins: [
        { sequence: 1, tablename: "members", foreignKey: "history_committees_members.member_id" },
        { sequence: 2, tablename: "people", foreignKey: "members.person_id" }
      ]
    },
    {
      field: "member.person.firstname", title: "Firstname", joins: [
        { sequence: 1, tablename: "members", foreignKey: "history_committees_members.member_id" },
        { sequence: 2, tablename: "people", foreignKey: "members.person_id" }
      ]
    },
    {
      field: "committee_role.displayName.fr", title: "Role", joins: [
        { sequence: 1, tablename: "committee_roles", foreignKey: "history_committees_members.committee_role_id" }
      ]
    },
    {
      field: 'member.person.person_tenure.displayName.fr', title: 'Tenure', joins: [
        { sequence: 1, tablename: "members", foreignKey: "history_committees_members.member_id" },
        { sequence: 2, tablename: "people", foreignKey: "members.person_id" },
        { sequence: 4, tablename: "person_tenures", foreignKey: "people.tenure_id" }
      ]
    },
    {
      field: 'assignment_date', title: 'Assignment date', type: 'date', filter: 'date', data: {
        format: (dataItem: any): string => {
          if (!dataItem.assignment_date) return '';
          const date = new Date(new Date(dataItem.assignment_date).getTime() + 1 * 60 * 60 * 1000);
          if (date.getFullYear().toString() == 'NaN') return '';
          return `${date.getDate().toString().padStart(2, '0')}/${(date.getMonth() + 1).toString().padStart(2, '0')}/${date.getFullYear()}`;
        }
      }
    },
    {
      field: 'deleted_at', title: 'Assignment end date', type: 'date', filter: 'date', data: {
        format: (dataItem: any): string => {
          if (!dataItem.deleted_at) return '';
          const date = new Date(new Date(dataItem.deleted_at).getTime() + 1 * 60 * 60 * 1000);
          if (date.getFullYear().toString() == 'NaN') return '';
          return `${date.getDate().toString().padStart(2, '0')}/${(date.getMonth() + 1).toString().padStart(2, '0')}/${date.getFullYear()}`;
        }
      }
    }
  ];
  public historyCommitteeMembersSorts = [
    {
      dir: 'asc', field: 'member.person.lastname', joins: [
        { sequence: 1, tablename: "members", foreignKey: "history_committees_members.member_id" },
        { sequence: 2, tablename: "people", foreignKey: "members.person_id" }
      ]
    }
  ]

  public onHistoryCommitteeMemberEditBtn(committee_member: any) {
    this.editCommitteeMemberModal.data = committee_member;
    if (!committee_member.assignment_date || committee_member.assignment_date.toString() == '0000-00-00 00:00:00') {
      this.editCommitteeMemberModal.data.assignment_date = undefined;
    }
    else if (committee_member.assignment_date) {
      this.editCommitteeMemberModal.data.assignment_date = new Date(committee_member.assignment_date);
    }
    this.editCommitteeMemberModal.data.deleted_at = new Date(committee_member.deleted_at);
    this.editCommitteeMemberModal.toggle();
  }

  public onComfirmCommitteeMemberEditBtn() {
    this.historyCommitteesMembersService.updateAssoc({
      'old': {
        id: this.editCommitteeMemberModal.data.id,
        member_id: this.editCommitteeMemberModal.data.member.id,
        committee_id: this.committees_id,
        committee_role_id: this.editCommitteeMemberModal.data.committee_role_id,
        assignment_date: this.committeesMemberFormatDate(this.editCommitteeMemberModal.data.assignment_date),
        deleted_at: this.committeesMemberFormatDate(this.editCommitteeMemberModal.data.deleted_at)
      },
      'new': {
        member_id: this.editCommitteeMemberModal.data.member.id,
        committee_id: this.committees_id,
        committee_role_id: this.editCommitteeMemberModal.data.committee_role.id,
        assignment_date: this.committeesMemberFormatDate(this.editCommitteeMemberModal.data.assignment_date),
        deleted_at: this.committeesMemberFormatDate(this.editCommitteeMemberModal.data.deleted_at)
      }
    }).then(r => {
      this.editCommitteeMemberModal.toggle();
      this.reloadGrid();
      this.notificationService.showNotification("Record Updated", 'success');
    });
  }

  private committeesMemberFormatDate(date: Date) {
    if (date == undefined) return undefined;
    return date.getFullYear() + '-' +
      (date.getMonth() + 1).toString().padStart(2, '0') + '-' +
      date.getDate().toString().padStart(2, '0') + ' ' +
      date.getHours().toString().padStart(2, '0') + ':' +
      date.getMinutes().toString().padStart(2, '0') + ':' +
      date.getSeconds().toString().padStart(2, '0');
  }

  private reloadGrid() {
    setTimeout(() => {
      this.historyCommitteeMembersGrid.updateGridDatas();
    }, 100);
  }
}
