import { Component, Input, ViewChild } from '@angular/core';
import { AppNotificationService } from 'src/app/app-notification.service';
import { ModalComponent } from 'src/app/ui/modal/modal.component';
import { CommitteesService } from '../committees.service';
import { environment } from 'src/environments/environment';
import { finalize } from 'rxjs';

@Component({
  selector: 'app-committees-appointments-statistics-modal',
  templateUrl: './committees-appointments-statistics-modal.component.html',
  styleUrls: ['./committees-appointments-statistics-modal.component.scss']
})
export class CommitteesAppointmentsStatisticsModalComponent{
  @Input() public committees_id: number = 0;

  @ViewChild("committeeAppointmentsStatisticsModal") committeeAppointmentsStatisticsModal: ModalComponent = new ModalComponent();

  constructor(
    protected notificationService: AppNotificationService,
    private committeesService: CommitteesService,
    public appNotificationService: AppNotificationService
  ) { }

  public onConfirmModal() {
    if(!this.committeeAppointmentsStatisticsModal.data.startDate || !this.committeeAppointmentsStatisticsModal.data.endDate){
      this.notificationService.showNotification('Date cannot be empty','error')
      return;
    }
    if(this.committeeAppointmentsStatisticsModal.data.startDate > this.committeeAppointmentsStatisticsModal.data.endDate){
      this.notificationService.showNotification('End date must be greater than start date','error')
      return;
    }
    this.appNotificationService.toggleLoader();

    this.committeesService.exportCommitteesAppointmentsStatistics(this.committees_id, this.committeeAppointmentsStatisticsModal.data.startDate,this.committeeAppointmentsStatisticsModal.data.endDate)
      .pipe(finalize(()=>{
        this.committeeAppointmentsStatisticsModal.toggle();
        this.appNotificationService.toggleLoader();
      }))
      .subscribe();

  }
}
