import { Injectable } from '@angular/core';
import { RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { lastValueFrom, of } from 'rxjs';
import { PersonService } from './person.service';

@Injectable({
  providedIn: 'root'
})
export class PersonResolver  {
  public element_id: string | number | undefined;

  constructor(
    private service: PersonService,
  ) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
    this.element_id = route.params.id;
    return this.element_id != 'new' ? this.service.find(this.element_id) : lastValueFrom(of({ data: {} }));
  }
}
