import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AppNotificationService } from 'src/app/app-notification.service';
import { AppTranslationsService } from 'src/app/app-translations.service';
import { AuthenticationService } from 'src/app/auth/authentication.service';
import { AppGridComponent } from 'src/app/ui/grid/grid.component';
import { KendoComponentHelper } from 'src/app/ui/kendo-component-helper/kendo-component-helper';
import { ModalComponent } from 'src/app/ui/modal/modal.component';
import { PageComponent } from 'src/app/ui/page/page.component';
import { ToolbarService } from 'src/app/ui/toolbar/toolbar.service';
import { BreadcrumbsService } from 'src/app/ui/breadcrumbs/breadcrumbs.service';

@Component({
  selector: 'app-translations',
  templateUrl: './translations.component.html',
  styleUrls: ['./translations.component.scss']
})
export class TranslationsComponent extends PageComponent implements OnInit, AfterViewInit {
  // Grid
  // ----
  @ViewChild('grid') grid!: AppGridComponent;
  columns: any[] = [
    { field: 'key', title: 'Key' },
    { field: 'value.fr', title: 'Value' }
  ];
  translationsActions: any[] = [
    { icon: 'plus', click: () => { this.onAddTranslation() } }
  ];

  // Modals
  // ------
  @ViewChild("addTranslationModal") addTranslationModal: ModalComponent = new ModalComponent();
  @ViewChild("deleteTranslationModal") deleteTranslationModal: ModalComponent = new ModalComponent();
  @ViewChild('editTranslationModal') editTranslationModal: ModalComponent = new ModalComponent();
  public translationsHelper: KendoComponentHelper = new KendoComponentHelper();


  // Services
  // --------
  constructor(
    public notificationService: AppNotificationService,
    public translateService: TranslateService,
    public authenticationService: AuthenticationService,
    public translationService: AppTranslationsService,
  ) {
    super();
  }

  // Lifecycle
  // ---------
  ngAfterViewInit(): void {
    this.grid.updateGridDatas();
  }

  ngOnInit(): void {
    this.translationService.all().then((r: any) => {
      this.translateService.reloadLang(this.translationService.currentLanguage);
    });
  }

  // Add Translation
  // ---------------
  onAddTranslation() {
    this.addTranslationModal.data = {
      key: this.grid.toolbar.searchValue ? this.grid.toolbar.searchValue : null,
      value: this.grid.toolbar.searchValue ? { en: this.grid.toolbar.searchValue, fr: this.grid.toolbar.searchValue } : { en: null, fr: null }
    };

    this.addTranslationModal.toggle();
  }

  onConfirmAddTranslationButtonClicked() {
    this.translationService.create(this.addTranslationModal.data).then(r => {
      this.notificationService.showNotification('Record Created', 'success');
      this.translateService.reloadLang(this.translationService.currentLanguage);
      window.location.reload();
    }).finally(() => { this.addTranslationModal.toggle(); });
  }

  // Edit Translation
  // ----------------
  onEditTranslationButtonClicked(dataItem: any) {
    this.editTranslationModal!.data = dataItem.value;
    this.editTranslationModal!.data.item = dataItem;

    this.editTranslationModal!.toggle();
  }

  onEditTranslationsConfirmed() {
    const element = this.editTranslationModal.data.item;
    delete this.editTranslationModal.data.item;
    this.translationService.update(element).then(r => {
      this.notificationService.showNotification('Record Updated', 'success');
      this.translateService.reloadLang(this.translationService.currentLanguage);
      window.location.reload();
    }).finally(() => { this.editTranslationModal.toggle(); });
  }

  // Delete Translation
  // ------------------
  onDeleteTranslationButtonClicked(dataItem: any) {
    this.deleteTranslationModal.data = dataItem;
    this.deleteTranslationModal.toggle();
  }

  onConfirmDeleteTranslationButtonClicked() {
    this.translationService.delete(this.translationsHelper.datas?.find((e: any) => e.id == this.deleteTranslationModal.data.id)).then(r => {
      this.notificationService.showNotification('Record Deleted', 'success');
      this.translateService.reloadLang(this.translationService.currentLanguage);
      window.location.reload();
    }).finally(() => { this.editTranslationModal.toggle(); });
  }

}
