import { Component, Input } from '@angular/core';
import { AppointmentStatusBackgroundColor, AppointmentStatusColor } from 'src/app/admin/appointments/appointment-statuses/appointment-status';
import { BaseCell } from '../base-cell';

@Component({
  selector: 'app-status-cell',
  templateUrl: './status-cell.component.html',
  styleUrls: ['./status-cell.component.scss']
})
export class StatusCellComponent extends BaseCell {
  @Input() color?: string;
  @Input() backgroundColor?: string;

  override ondataItemChange() {
    this.color = this.data?.color || this.data?.colorFunction(this.dataItem);
    this.backgroundColor = this.data?.backgroundColor || this.data?.backgroundColorFunction(this.dataItem);
  }
}

export class StatusCellData {
  public colorFunction: Function = () => AppointmentStatusColor.Default;
  public color?: string;

  public backgroundColor?: string;
  public backgroundColorFunction: Function = () => AppointmentStatusBackgroundColor.Default;

  constructor(element?: Partial<StatusCellData>) {
    Object.assign(this, element);
  }
}
