import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppApiService } from 'src/app/app-api.service';
import { AppNotificationService } from 'src/app/app-notification.service';

@Injectable({
  providedIn: 'root'
})
export class AppointmentAttendeeResponsesService extends AppApiService {
  public url: string | null = 'cesew/appointmentAttendeeResponses';

  constructor(
    protected http: HttpClient,
    protected notificationService: AppNotificationService,
    private appApiService: AppApiService
  ) {
    super(http, notificationService);
  }

  public updateResponse(personId: number, appointmentId: number, appointmentAttendeeResponseId: number) {
    return this.appApiService.post({ responseId: appointmentAttendeeResponseId }, `aad/user/${personId}/events/${appointmentId}/updateResponse`);
  }
}
