import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BreadcrumbsService } from './breadcrumbs.service';
import { EventsService } from 'src/app/core/services/events/events.service';
import { BreadcrumbEvents } from './breadcrumb-events';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss']
})
export class BreadcrumbsComponent implements OnInit {
  private routesData: any;
  public items: any[] = [];
  private subscription?: Subscription;

  constructor(
    private router: Router,
    private breadcrumbsService: BreadcrumbsService,
    private eventsService: EventsService

  ) {
    this.initRoutes();
  }

  ngOnInit(): void {
    this.eventsService.on(BreadcrumbEvents.Reload).subscribe(e => {
      this.reload();
    });
    this.subscription = this.breadcrumbsService.breadcrumbsUpdater$.subscribe(() => this.reload());
  }

  public ngOnDestroy(): void {
    this.routesData.unsubscribe();
    this.subscription?.unsubscribe();
  }

  private initRoutes(): void {

    this.routesData = this.router.events.subscribe(() => {
      // Exclude query parameters from URL
      const route = this.router.url;
      this.items = route
        .substring(
          0,
          route.indexOf("?") !== -1 ? route.indexOf("?") : route.length
        )
        .split("/")
        .filter((segment: string) => {
          let excludedItems = ['', 'admin'];
          return !excludedItems.includes(segment);
        })
        .map((segment: string) => {
          return this.breadcrumbsService.getSegmentName(segment);
        });
    });
  }

  public reload() {
    const route = this.router.url;

    this.items = route
      .substring(
        0,
        route.indexOf("?") !== -1 ? route.indexOf("?") : route.length
      )
      .split("/")
      .filter((segment: string) => {
        let excludedItems = ['', 'admin'];
        return !excludedItems.includes(segment);
      })
      .map((segment: string) => {
        return this.breadcrumbsService.getSegmentName(segment);
      });
  }

  public onHomeButtonClicked() {
    this.router.navigateByUrl('admin/');
  }
}
