import { Component, ViewChild } from '@angular/core';
import { CarMileageExpensesComponent } from '../car-mileage-expenses/car-mileage-expenses.component';
import { SncbMileageExpensesComponent } from '../sncb-mileage-expenses/sncb-mileage-expenses.component';

@Component({
  selector: 'app-mileage-expenses-details',
  templateUrl: './mileage-expenses-details.component.html',
  styleUrls: ['./mileage-expenses-details.component.scss']
})
export class MileageExpensesDetailsComponent {

  @ViewChild('carMileageExpenses') carMileageExpenses?: CarMileageExpensesComponent;
  @ViewChild('sncbMileageExpenses') sncbMileageExpenses?: SncbMileageExpensesComponent;

  public async save(): Promise<void> {
    this.sncbMileageExpenses?.save();
    this.carMileageExpenses?.save();
  }
}
