import { Component, Input } from '@angular/core';
import { BaseCell } from '../base-cell';

@Component({
  selector: 'app-appointment-committees-cell',
  templateUrl: './appointment-committees-cell.component.html',
  styleUrls: ['./appointment-committees-cell.component.scss']
})
export class AppointmentCommitteesCellComponent extends BaseCell<any> {
  @Input() public set dataItem(value: any) {
    this._dataItem = value;
    this.data = this.data || this.column.data;
    this.value = this._dataItem[this.column.property];
    this.ondataItemChange();
};
}
