<app-grid
  #grid
  [columns]="columns"
  [service]="service"
  redirectUrl="admin/appointments"
  (edit)="onEdit($event)"
  [exportable]="false"
  [searchable]="true"
  [baseSorts]="baseSorts"
  [deletable]="false"
  [editable]="false"
  [actions]="toolbarActions"
  localStorageKey="state-grid-appointments"
  [usedApiRouteList]="true"
></app-grid>

<kendo-dialog
  title="{{ 'Import an appointment' | translate }}"
  *ngIf="opened"
  (close)="opened = !opened"
  width="77vw"
  height="88vh"
>
  <dx-scheduler
    #scheduler
    [dataSource]="appointments"
    [currentDate]="currentDate"
    timeZone="Europe/Brussels"
    currentView="week"
    [views]="schedulerViews"
    [editing]="{
      allowAdding: false,
      allowDeleting: false,
      allowDragging: false,
      allowResizing: false,
      allowTimeZoneEditing: false,
      allowUpdating: false
    }"
    (onOptionChanged)="onOptionChanged($event)"
    (onAppointmentDblClick)="onConfirmImportAppointment($event)"
    height="100%"
    [showAllDayPanel]="false"
  ></dx-scheduler>
</kendo-dialog>
