<app-toolbar
    #toolbar
    [searchable]="true"
    [exportable]="true"
    (search)="search($event)"
    (export)="onExport($event)"
    [(searchValue)]="searchValue"
></app-toolbar>

<kendo-grid
    #grid="kendoGrid"
    [loading]="loading"
    [kendoGridBinding]="dataResult"
    [sortable]="true"
    [sort]="state.sort"
    [filterable]="'menu'"
    [filter]="state.filter"
    (cellClick)="clickedRowItem = $event.dataItem"
    (dataStateChange)="onDataStateChange($event)"
    (dblclick)="onEdit(clickedRowItem)"
>
    <kendo-grid-column
        *ngFor="let col of columns"
        [field]="col.field"
        [filter]="col.filter"
        title=""
    >
        <ng-template
            kendoGridFilterMenuTemplate
            let-filter="filter"
            let-column="column"
            let-filterService="filterService"
        >
            <kendo-grid-string-filter-menu
                [column]="column"
                [filter]="filter"
                [filterService]="filterService"
                [extra]="false"
            > </kendo-grid-string-filter-menu>
            <kendo-grid-boolean-filter-menu
                *ngIf="col.filter == 'boolean'"
                [column]="column"
                [filter]="filter"
                [filterService]="filterService"
            > </kendo-grid-boolean-filter-menu>
            <app-multicheck-filter
                *ngIf="col.filter == 'multi'"
                [currentFilter]="filter"
                [field]="col.field"
                [filterService]="filterService"
                [service]="col.service"
            ></app-multicheck-filter>
        </ng-template>
        <ng-template
            kendoGridHeaderTemplate
            let-column
            let-columnIndex="columnIndex"
        >
            <ng-container *ngIf="col.title">{{col.title | translate}}</ng-container>
        </ng-template>
    </kendo-grid-column>
</kendo-grid>
