import { Component, Input, OnInit } from '@angular/core';
import { CompaniesService } from '../../admin/companies/companies.service';

@Component({
  selector: 'app-multicheck-filter',
  templateUrl: './multicheck-filter.component.html',
  styleUrls: ['./multicheck-filter.component.scss']
})
export class MulticheckFilterComponent implements OnInit {
  @Input() public currentFilter?: any;
  @Input() public field?: string;
  @Input() public filterService?: any;
  @Input() public service?: any;

  public items: any[] = [];
  public filteredItems: any[] = [];

  private selectedItems: any[] = [];

  ngOnInit(): void {
    this.service.all({ sort: [{ dir: "asc", field: "displayName.fr" }] }).then((res: any) => {
      this.filteredItems = this.items = res.data;

      this.currentFilter.filters.forEach((filter: any) => {
        this.selectedItems.push(filter.value);
      });
    });
  }

  public onInput(e: any): void {
    this.filteredItems = this.items.filter((item: any) => item.displayName.fr.toLowerCase().includes(e.target.value.toLowerCase()));
  }

  public isItemSelected(item: any): boolean {
    return this.selectedItems.some((x: any) => x === item.displayName.fr);
  }

  public onSelectionChange(item: any): void {
    if (this.selectedItems.some(x => x === item.displayName.fr)) {
      this.selectedItems = this.selectedItems.filter(x => x !== item.displayName.fr);
    } else {
      this.selectedItems.push(item.displayName.fr);
    }

    this.filterService.filter({
      filters: this.selectedItems.map(x => ({
        field: this.field,
        operator: 'eq',
        value: x
      })),
      logic: 'or'
    });
  }
}
