<kendo-card class="w-100 rounded-0">
    <kendo-card-body>
          <kendo-tabstrip [keepTabContent]="true">
                <kendo-tabstrip-tab
                    title="{{'Mileage expenses' | translate}}"
                    [selected]="true"
                >
                    <ng-template kendoTabContent>
                      <app-mileage-expenses-details #mileageExpensesDetail></app-mileage-expenses-details>
                    </ng-template>
                </kendo-tabstrip-tab>
            </kendo-tabstrip>
    </kendo-card-body>
</kendo-card>
