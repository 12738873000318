export class Committee {
    public id!: string;
    public code?: string;
    public displayName?: string;
    public microsoft_id?: string;
    public parent_id?: number
    public computeSncbCost?: boolean;
    public computeCarCost?: boolean;

    constructor() { }
}
