<app-toolbar [searchable]="true" [exportable]="true" (search)="search($event)" (export)="onExport($event)"></app-toolbar>

<kendo-treelist
    [sortable]="true"
    [sort]="sorts"
    [filter]="filters"
    [loading]="loading"
    [kendoTreeListFlatBinding]="committees"
    parentIdField="parent_id"
    idField="id"
    kendoTreeListSelectable
    [selectable]="{ multiple: true }"
    [(selectedItems)]="selectedCommittees"
    (cellClick)="currentCommittee = $event.dataItem"
    (dblclick)="onEditButtonClicked(currentCommittee)"
    kendoTreeListExpandable
>
    <kendo-treelist-checkbox-column
        [width]="40"
        [checkChildren]="true"
        [showSelectAll]="true"
    ></kendo-treelist-checkbox-column>
    
    <kendo-treelist-column
        [expandable]="true"
        field="displayName.fr"
        [title]="'Name' | translate"
    ></kendo-treelist-column>
    <kendo-treelist-column title="" [width]="40" headerClass="text-center">
        <ng-template kendoTreeListCellTemplate let-dataItem>
            <span class="d-inline-block rounded-circle bg-{{dataItem.microsoft_id ? 'success': 'danger'}}" style="width: 10px; height: 10px;"></span>
        </ng-template>
    </kendo-treelist-column>
    <kendo-treelist-column title="{{'Actions' | translate}}" [width]="100" headerClass="text-center">
        <ng-template kendoTreeListCellTemplate let-dataItem>
            <div class="d-flex justify-content-center">
                <i [hasPermission]="['members.update']" [toRemove]="true" class="fas fa-pen text-info p-2" role="button" (click)="onEditButtonClicked(dataItem)"></i>
            </div>
        </ng-template>
    </kendo-treelist-column>
</kendo-treelist>