import { NgModule, LOCALE_ID, Injector } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateModule, TranslatePipe } from '@ngx-translate/core';
import { DrawerModule, LayoutModule } from '@progress/kendo-angular-layout';
import { AppComponent } from './app.component';
import { MsalGuard, MsalModule, MsalRedirectComponent } from '@azure/msal-angular';
import { PublicClientApplication } from '@azure/msal-browser';
import { AppRoutingModule } from './app-routing.module';
import { LoginComponent } from './auth/login/login.component';
import { AdminComponent } from './admin/admin.component';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { BrowserModule } from '@angular/platform-browser';
import { LabelModule } from '@progress/kendo-angular-label';
import { HttpClientModule } from '@angular/common/http';
import { AppConfig } from './app-config';
import { MembersComponent } from './admin/members/members.component';
import { HomeComponent } from './admin/home/home.component';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { NgxUiLoaderHttpModule, NgxUiLoaderModule } from 'ngx-ui-loader';
import { SettingsComponent } from './admin/settings/settings.component';
import { ExcelModule, GridModule } from '@progress/kendo-angular-grid';
import { TreeListModule } from '@progress/kendo-angular-treelist';
import { CommitteeDetailsComponent } from './admin/committees/committee-details/committee-details.component';
import { DialogsModule, WindowModule } from '@progress/kendo-angular-dialog';
import { ModalConfirmComponent } from './ui/modal-confirm/modal-confirm.component';
import { NotificationModule } from '@progress/kendo-angular-notification';
import { IndicatorsModule } from '@progress/kendo-angular-indicators';
import { ExcelExportModule } from '@progress/kendo-angular-excel-export';
import { ModalComponent } from './ui/modal/modal.component';
import { ModalContentDirective } from './ui/modal/modal-content.directive';
import { TranslationModalComponent } from './ui/translation-modal/translation-modal.component';
import { TranslatedInputComponent } from './ui/translated-input/translated-input.component';
import { ContactComponent } from './admin/contacts/contact.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MenuModule } from '@progress/kendo-angular-menu';
import { NavigationModule } from '@progress/kendo-angular-navigation';
import { BreadcrumbsComponent } from './ui/breadcrumbs/breadcrumbs.component';
import { AppToolbarComponent } from './ui/toolbar/toolbar.component';
import { PageComponent } from './ui/page/page.component';
import { CommitteesComponent } from './admin/committees/committees.component';
import { AddressComponent } from './admin/addresses/address.component';
import { UserTypeBadgeComponent } from './ui/user-type-badge/user-type-badge.component';
import { AppGridComponent } from './ui/grid/grid.component';
import { AfterValueChangedDirective } from './ui/after-value-changed.directive';
import { IntlModule } from "@progress/kendo-angular-intl";
import { TenuresComponent } from './admin/companies/tenures/tenures.component';
import { CompanyRolesComponent } from './admin/companies/company-roles/company-roles.component';
import { CompaniesComponent } from './admin/companies/companies.component';
import { CompanyDetailsComponent } from './admin/companies/company-details/company-details.component';
import { TenureDetailsComponent } from './admin/companies/tenures/tenure-details/tenure-details.component';
import { CompanyRoleDetailsComponent } from './admin/companies/company-roles/company-role-details/company-role-details.component';
import { ContactSmallComponent } from './admin/contacts/contact-small/contact-small.component'
import { RelationCardComponent } from './admin/relations/relation-card/relation-card.component'
import { PersonDetailsComponent } from './admin/people/person-details/person-details.component'
import "@progress/kendo-angular-intl/locales/fr/all";
import { TranslationsComponent } from './admin/settings/translations/translations.component';
import { InputCustomValueComponent } from './ui/input-custom-value/input-custom-value.component';
import { PopupModule } from '@progress/kendo-angular-popup';
import { UserPermissionDirective } from './auth/user-permission.directive';
import { GooglePlacesComponent } from './admin/addresses/google-places/google-places.component';
import { PeopleComponent } from './admin/people/people.component';
import { PersonFormComponent } from './admin/people/person-form/person-form.component';
import { ContactFormComponent } from './admin/contacts/contact-form/contact-form.component';
import { AddressFormComponent } from './admin/addresses/address-form/address-form.component';
import { RelationFormComponent } from './admin/relations/relation-form/relation-form.component';
import { PersonCommunicationFormComponent } from './admin/people/person-communication-form/person-communication-form.component';
import { AppTreeListComponent } from './ui/treelist/treelist.component';
import { MulticheckFilterComponent } from './ui/multicheck-filter/multicheck-filter.component';
import { AssistantsComponent } from './admin/assistants/assistants.component';
import { AppointmentsComponent } from './admin/appointments/appointments.component';
import { AppointmentDetailsComponent } from './admin/appointments/appointment-details/appointment-details.component';
import { DxSchedulerModule } from 'devextreme-angular';
import { DatePickerModule } from '@progress/kendo-angular-dateinputs';
import { ArrowStepperItemComponent } from './ui/arrow-stepper-item/arrow-stepper-item.component';
import { AppInjectorService } from './core/services/injector/app-injector.service';
import frMessages from "devextreme/localization/messages/fr.json";
import { locale, loadMessages } from "devextreme/localization";
import { StatusCellComponent } from './ui/grid/cells/status-cell/status-cell.component';
import { IconCellComponent } from './ui/grid/cells/icon-cell/icon-cell.component';
import { ImageCellComponent } from './ui/grid/cells/image-cell/image-cell.component';
import { BooleanCellComponent } from './ui/grid/cells/boolean-cell/boolean-cell.component';
import { DateCellComponent } from './ui/grid/cells/date-cell/date-cell.component';
import { AppointmentCommitteesCellComponent } from './ui/grid/cells/appointment-committees-cell/appointment-committees-cell.component';
import { EditorModule } from '@progress/kendo-angular-editor';
import { HistoryCommitteesMembersGridComponent } from './admin/committees/history-committees-members-grid/history-committees-members-grid.component';
import { ChangePersonPrimaryEmailModalComponent } from './admin/people/change-person-primary-email-modal/change-person-primary-email-modal.component';
import { CommitteesAppointmentsStatisticsModalComponent } from './admin/committees/committees-appointments-statistics-modal/committees-appointments-statistics-modal.component';
import { ConfigurationComponent } from './admin/configurations/configuration.component';
import { MileageExpensesDetailsComponent } from './admin/configurations/mileage-expenses-details/mileage-expenses-details.component';
import { CarMileageExpensesComponent } from './admin/configurations/car-mileage-expenses/car-mileage-expenses.component';
import { SncbMileageExpensesComponent } from './admin/configurations/sncb-mileage-expenses/sncb-mileage-expenses.component';
import { UploadsModule } from '@progress/kendo-angular-upload';


loadMessages(frMessages);
locale('fr');

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    AdminComponent,
    MembersComponent,
    HomeComponent,
    SettingsComponent,
    CommitteeDetailsComponent,
    CommitteesComponent,
    ContactComponent,
    ModalConfirmComponent,
    AddressComponent,
    ModalComponent,
    ModalContentDirective,
    TranslationModalComponent,
    TranslatedInputComponent,
    AppToolbarComponent,
    BreadcrumbsComponent,
    PageComponent,
    UserTypeBadgeComponent,
    AppGridComponent,
    AfterValueChangedDirective,
    TranslationsComponent,
    TenuresComponent,
    CompanyRolesComponent,
    CompaniesComponent,
    CompanyDetailsComponent,
    TenureDetailsComponent,
    CompanyRoleDetailsComponent,
    InputCustomValueComponent,
    ContactSmallComponent,
    RelationCardComponent,
    PersonDetailsComponent,
    UserPermissionDirective,
    GooglePlacesComponent,
    PeopleComponent,
    PersonFormComponent,
    ContactFormComponent,
    AddressFormComponent,
    RelationFormComponent,
    PersonCommunicationFormComponent,
    AppTreeListComponent,
    MulticheckFilterComponent,
    AssistantsComponent,
    AppointmentsComponent,
    AppointmentDetailsComponent,
    ArrowStepperItemComponent,
    StatusCellComponent,
    IconCellComponent,
    ImageCellComponent,
    BooleanCellComponent,
    DateCellComponent,
    AppointmentCommitteesCellComponent,
    HistoryCommitteesMembersGridComponent,
    ChangePersonPrimaryEmailModalComponent,
    CommitteesAppointmentsStatisticsModalComponent,
    ConfigurationComponent,
    MileageExpensesDetailsComponent,
    CarMileageExpensesComponent,
    SncbMileageExpensesComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    MsalModule.forRoot(new PublicClientApplication(AppConfig.mPublicApplicationConfig), AppConfig.mGuarConfig, AppConfig.mInterceptorConfiguration),
    LayoutModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    InputsModule,
    HttpClientModule,
    NgxUiLoaderModule.forRoot(AppConfig.ngxUiLoaderConfig),
    NgxUiLoaderHttpModule,
    LabelModule,
    DrawerModule,
    TranslateModule.forRoot(AppConfig.translationConfig),
    DropDownsModule,
    ButtonsModule,
    GridModule,
    DialogsModule,
    WindowModule,
    NotificationModule,
    IndicatorsModule,
    ExcelModule,
    ExcelExportModule,
    FontAwesomeModule,
    MenuModule,
    NavigationModule,
    IntlModule,
    PopupModule,
    TreeListModule,
    DxSchedulerModule,
    DatePickerModule,
    EditorModule,
    UploadsModule
  ],
  providers: [
    AppConfig.httpInterceptorProviderConfig,
    MsalGuard,
    TranslatePipe,
    {
      provide: LOCALE_ID,
      useValue: "fr-FR",
    },
  ],
  bootstrap: [AppComponent, MsalRedirectComponent]
})


export class AppModule {
  constructor(injector: Injector) {
    AppInjectorService.injector = injector;
  }

}
