import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { AuthenticationResult, EventMessage, EventPayload, EventType, InteractionStatus } from '@azure/msal-browser';
import { Subject } from 'rxjs';
import { filter, takeUntil, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { MsalToken } from './msal-token';
import { MSALUser } from './msal-user';
import { UsersService } from './users/users.service';
import { State } from '@progress/kendo-data-query';

const GRAPH_ENDPOINT_ME = '/me';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  private readonly _destroying$ = new Subject<void>();
  public isLogged: boolean = false;
  public user: any | null = null;
  public token: EventPayload = null;
  public loginEvent: EventEmitter<MSALUser> = new EventEmitter<MSALUser>();
  public logoutEvent: EventEmitter<null> = new EventEmitter<null>();
  public aquireTokenEvent: EventEmitter<EventPayload> = new EventEmitter<EventPayload>();
  public connectedEvent: EventEmitter<null> = new EventEmitter<null>();

  constructor(
    private msalBroadcastService: MsalBroadcastService,
    private msalService: MsalService,
    private http: HttpClient,
    private userService: UsersService
  ) { }

  public registerForAuthenticationEvent() {
    // Called on first authentication
    // ------------------------------

    this.msalBroadcastService.msalSubject$.subscribe((result: EventMessage) => {
      switch (result.eventType) {
        case EventType.LOGIN_SUCCESS: this.onLoginSuccess(); break;
        case EventType.LOGOUT_SUCCESS: this.onLogoutSuccess(); break;
        case EventType.ACQUIRE_TOKEN_SUCCESS: this.onAcquireTokenSuccess(result); break;
        case EventType.LOGIN_FAILURE: this.logout(); break;
        default: break;
      }
    });

    this.msalBroadcastService.inProgress$.pipe(filter((status: InteractionStatus) => status === InteractionStatus.None), takeUntil(this._destroying$)).subscribe(() => {
      this.connectedEvent.emit();
    });
  }

  // MSAL
  // ----
  private onLoginSuccess() {
    this.loginEvent.emit();
  }

  private onLogoutSuccess() {
    this.user = null;
    this.token = null;
    this.logoutEvent.emit();
  }

  private onAcquireTokenSuccess(result: EventMessage) {
    this.token = result.payload;
    this.aquireTokenEvent.emit(this.token);
  }

  public unregisterForAuthenticationEvent() {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }

  public login() {
    this.msalService.loginRedirect();
  }

  public logout() {
    this.msalService.logoutRedirect({
      postLogoutRedirectUri: environment.frontendBaseURL
    });
  }

  public fetchCurrentUserInformations() {
    return this.http.get(environment.mGraphEndoint + GRAPH_ENDPOINT_ME).pipe(
      tap((response: any) => {
        let state: State = {
          filter: {
            logic: 'and', filters: [
              { field: 'email', operator: 'eq', value: response.userPrincipalName, },
            ],
          }
        };

        this.userService.get(state).then((r: any) => {
          this.user = r.data[0];
        });
      })
    )
  }

  // Permission Management
  // ---------------------
  hasOneRole(role_names: string[]) {
    let hasOneRole = false;
    role_names.forEach(role_name => {
      hasOneRole = !hasOneRole ? this.hasRole(role_name) : hasOneRole;
    })
    return hasOneRole;
  }

  hasRole(role_name: string) {
    let user_roles = this.user!.roles.map((r: any) => r.name);
    return user_roles.filter((r: any) => r === role_name).length > 0;
  }

  hasOnePermission(permission_names: string[]) {
    let hasOnePermission = false;
    permission_names.forEach(permission_name => {
      hasOnePermission = !hasOnePermission ? this.hasPermission(permission_name) : hasOnePermission;
    })
    return hasOnePermission;
  }

  hasPermission(permission_name: string) {
    if (!this.user || !this.user.all_permissions) {
      return false;
    }
    return this.user.all_permissions.filter((p: any) => p === permission_name).length > 0;
  }
}