<div class="px-4 py-3 bg-white d-flex justify-content-start align-items-center">
    <!-- Home -->
    <!-- <button class="btn btn-link me-1" (click)="onHomeButtonClicked()"><i class=" fas fa-home ms-1"></i></button>
    <i class="fas fa-chevron-right"></i> -->


    <ng-container *ngFor="let item of items; let last = last;">
        <button *ngIf="!last" class="btn btn-link" (click)="item.click? item.click($event):null"><i class="fas fa-{{item.icon}} me-1"></i><span>{{item.text|translate}}</span></button>
        <span class="p-2" *ngIf="last"><i class="fas fa-{{item.icon}} me-1"></i><span>{{item.text |translate}}</span></span>
        <span *ngIf="!last"><i class="fas fa-chevron-right"></i></span>
    </ng-container>
</div>