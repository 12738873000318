<app-grid
    #grid
    [columns]=columns
    [service]="service"
    redirectUrl="admin/members"
    [exportable]="true"
    [searchable]="true"
    [baseSorts]="membersSorts"
    localStorageKey="state-grid-members"
></app-grid>


<app-modal
    #historyModal
    title="Export history"
    (confirm)="onConfirmExportHistory(historyModal.data)"
>
    <ng-template modalContent>
        <kendo-label text="{{'Member' | translate}}"></kendo-label>
        <kendo-dropdownlist
            [loading]="membersHelperList.filteredDatas.length === 0"
            class="mb-3"
            [data]="membersHelperList.filteredDatas"
            [filterable]="true"
            [(ngModel)]="historyModal.data.member"
            textField="fullname"
            (filterChange)="membersHelperList.handleFilter('fullname', $event)"
            valueField="id"
        ></kendo-dropdownlist>
    </ng-template>
</app-modal>