import { Component, OnInit } from '@angular/core';
import { PageComponent } from 'src/app/ui/page/page.component';
import { ToolbarService } from 'src/app/ui/toolbar/toolbar.service';
import { CommitteesService } from './committees.service';
import { BreadcrumbsService } from 'src/app/ui/breadcrumbs/breadcrumbs.service';
import { AppNotificationService } from 'src/app/app-notification.service';
import { environment } from 'src/environments/environment';
import { Committee } from './committee.modele';
import { ActivatedRoute, Router } from '@angular/router';
import { Person } from '../people/person';
import { ExportCallback } from 'src/app/ui/toolbar/toolbar.component';
import { finalize } from 'rxjs';

@Component({
  selector: 'app-committees',
  templateUrl: './committees.component.html',
  styleUrls: ['./committees.component.scss']
})
export class CommitteesComponent extends PageComponent implements OnInit {

  public selectedCommittees: any[] = [];


  constructor(
    public service: CommitteesService,
    private notificationService: AppNotificationService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
  ) {
    super();
  }

  public committees: Committee[] = [];
  public loading: boolean = true;
  public filters: any = {};
  public sorts: any[] = [{ dir: 'asc', field: 'displayName.fr' }];

  ngOnInit(): void {
    super.ngOnInit();

    this.service.all().then((committees: any) => {
      this.committees = committees.data;
      this.loading = false;
    })

    this.addAction({ code: 'send_mail', text: 'Send Mail', icon: 'envelope', click: () => this.onSendMailButtonClicked(), btnStyle: 'outline-primary' });

  }

  public search(inputValue: string | null): void {
    this.filters = { logic: 'and', filters: [{ field: 'displayName.fr', operator: 'contains', value: inputValue }] };
  }

  public onExport(cb: ExportCallback): void {
    const selectedItem = this.selectedCommittees.map((sc: any) => sc.itemKey);

    if (selectedItem.length) {
      this.service?.exportMembers(selectedItem).pipe(finalize(cb)).subscribe();
    } else {
      this.notificationService.showNotification('Must select at least 1 committee', 'error');
      cb();
    }
  }

  public currentCommittee: Committee = new Committee();
  public onEditButtonClicked(committee: Committee) {
    if (!committee.id) return;
    this.router.navigate([committee.id], { relativeTo: this.activatedRoute });
  }

  public onSendMailButtonClicked() {
    const selectedItem = this.selectedCommittees.map((sc: any) => sc.itemKey);

    if (!selectedItem || selectedItem.length == 0) {
      this.notificationService.showNotification('Must select at least 1 committee', 'error');
    }

    this.service.getMembersOf(selectedItem).then((r: any) => {
      const primary_emails = r.map((e: Person) => e.primary_email);
      const secondary_emails = r.map((e: Person) => e.secondary_email);
      const emails = [...primary_emails, ...secondary_emails];
      const to = emails.filter((e, i, arr) => {
        return arr.indexOf(arr.find(t => t === e)) === i;
      }).filter((e: string) => e && e != "");

      window.open('mailto:' + to.join(';'));
    });
  }
}
