import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppApiService } from 'src/app/app-api.service';
import { AppNotificationService } from 'src/app/app-notification.service';

@Injectable({
  providedIn: 'root'
})
export class SncbMileageExpensesService extends AppApiService {
  public url: string | null = 'cesew/sncbMileageExpenses';

  constructor(
    protected http: HttpClient,
    protected notificationService: AppNotificationService,
  ) {
    super(http, notificationService);
  }

  public importFile(filePath:string){
    return this.get(filePath, this.url + '/importFile');
  }

}
