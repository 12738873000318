import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { State } from '@progress/kendo-data-query';
import { Observable } from 'rxjs';
import { AppApiService } from 'src/app/app-api.service';
import { AppNotificationService } from 'src/app/app-notification.service';

@Injectable({
  providedIn: 'root'
})
export class CommitteesService extends AppApiService {

  public url: string | null = 'cesew/committees';

  constructor(
    protected http: HttpClient,
    protected notificationService?: AppNotificationService
  ) {
    super(http, notificationService);
  }

  getCommittees(): Promise<Object | undefined> {
    return this.get();
  }

  getCommitteeById(committeeId: string): Promise<Object | undefined> {
    return this.get();
  }

  getMembersOf(committeeIds: any[]): Promise<Object | undefined> {
    return this.get(committeeIds, this.url + '/members');
  }

  exportMembers(committeeIds: any[]): Observable<HttpResponse<Blob>> {
    return this.downloadFile(this.url + '/exportmembers', committeeIds);
  }

  sendMailToMembers(committeeIds: any[]): Promise<Object | undefined> {
    return this.get(committeeIds, this.url + '/sendMailToMembers');
  }

  exportCommitteesAppointmentsStatistics(committeeId: number, startDate: Date, endDate: Date): Observable<HttpResponse<Blob>> {
    let start = startDate.toLocaleDateString('fr-FR', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric'
    });
    let end = endDate.toLocaleDateString('fr-FR', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric'
    });

    let url = `cesew/committees/${committeeId}/exportAppointmentsStatistics?start=${start}&end=${end}`;
    return this.downloadFile(url);
    }
}
