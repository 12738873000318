import { AfterContentChecked, Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AppTranslationsService } from '../app-translations.service';
import { AuthenticationService } from '../auth/authentication.service';
import { ToolbarService } from '../ui/toolbar/toolbar.service';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})
export class AdminComponent implements OnInit , AfterContentChecked{
  isMobile: boolean = false;

  public userMenuItems: any[] = [];
  public drawerMenuItems: Array<any> = []

  constructor(
    public authenticationService: AuthenticationService,
    private translateService: TranslateService,
    public translationService: AppTranslationsService,
    public toolbarService: ToolbarService
  ) { }
  ngAfterContentChecked(): void {     
    if(this.authenticationService.user?.roles && this.drawerMenuItems.length==0){
      this.drawerMenuItems = [
        { text: 'Members', icon: 'users', route: '/admin/members', visible: true },
        { text: 'Communications', icon: 'user-group', route: '/admin/communications', visible: true },
        { text: 'Assistants', icon: 'people-group', route: '/admin/assistants', visible: true },
        { text: 'Committees', icon: 'folder', route: '/admin/committees', visible: true },
        { text: 'Appointments', icon: 'calendar-check', route: '/admin/appointments', visible: true },
        { text: 'Configurations', icon: 'gear', route: '/admin/configurations', visible: this.hasOneRole(['admin', 'super-admin']) },
      ];
    }
  }
 

  ngOnInit(): void {
    var ua = navigator.userAgent;

    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(ua)) { this.isMobile = true; }
    else { this.isMobile = false; }

    this.userMenuItems = [{
      id: 'logout', text: this.translateService.instant('Logout'),
    }];
    
    
  }

 

  public onProfileButtonItemClicked(e: any) {
    if (e.id == 'logout') {
      this.authenticationService.logout();
    }
  }

  public hasOneRole(roles: string[]) {
    return this.authenticationService.hasOneRole(roles);
  }
}
