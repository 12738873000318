import { Component, Input, OnInit } from "@angular/core";
import { fromDotNotation } from "src/app/core/tools";

@Component({
    selector: 'base-cell',
    template: ''
})
export class BaseCell<T = string> {
    protected _dataItem: any;
    @Input() public set dataItem(value: any) {
        this._dataItem = value;
        this.data = this.data || this.column.data;
        this.value = fromDotNotation(this.dataItem, this.column.field);
        this.ondataItemChange();
    };
    public get dataItem(): any { return this._dataItem; };

    @Input() public column: any;
    public data?: any;
    public value?: T;

    public ondataItemChange() { };
}
