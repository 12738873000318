import { Component, ElementRef, EventEmitter, Input, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { forkJoin, of, timer } from 'rxjs';
import { KendoComponentHelper } from 'src/app/ui/kendo-component-helper/kendo-component-helper';
import { AddressesService } from './addresses.service';
import { GooglePlaceAddress } from './google-places/GooglePlaceAddress.model';
import { CountryService } from './countries/country.service';
import { Country } from './countries/country';
import { CardComponent } from '@progress/kendo-angular-layout';

@Component({
  selector: 'app-address',
  templateUrl: './address.component.html',
  styleUrls: ['./address.component.scss']
})
export class AddressComponent implements OnInit {

  @Input() address: any;
  @Output() addressChange: EventEmitter<any> = new EventEmitter<any>();

  @Output() delete: EventEmitter<any> = new EventEmitter<any>();

  protected loading : boolean = false;


  constructor(
    private countryService: CountryService,
    private renderer: Renderer2
  ) { }

  ngOnInit(): void {

  }

  public onDeleteButtonClicked() {
    this.delete.emit(this.address);
  }

  public onGoogleAddress(googleAddress: GooglePlaceAddress) {
    this.startLoading();
    let address = this.address.address;
    const addressInitialState = JSON.parse(JSON.stringify(address));

    address.street = googleAddress.street?.long_name ?? address.street;
    address.number = googleAddress.number?.long_name ?? address.number;


    if (googleAddress.postalCode) {
      address.postalCode = googleAddress.postalCode.long_name;
      address.localityName = googleAddress.locality?.long_name.toUpperCase();
    }

    if (googleAddress.country) {
      this.countryService.findByCode(googleAddress.country.short_name)
        .subscribe({
          error:(e)=>{
            Object.assign(address,addressInitialState);
            this.stopLoading();
          },
          next : (value : Country)=>{
            address.country = value;
            address.country_id = value.id;
          },
          complete:()=>{
            this.stopLoading();
          }
        });
    }
  }

  private startLoading() : void{
    this.loading = true;
  }

  private stopLoading() : void{
    this.loading = false;
  }
}
