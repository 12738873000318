import { Component } from '@angular/core';
import { BaseCell } from '../base-cell';

@Component({
  selector: 'app-image-cell',
  templateUrl: './image-cell.component.html',
  styleUrls: ['./image-cell.component.scss']
})
export class ImageCellComponent extends BaseCell {


}

export class ImageCellData {
  public src?: string;
  public activeSrc?: string;
  public height?: string;

  constructor(element?: Partial<ImageCellData>) {
    Object.assign(this, element);
  }
}