import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CompositeFilterDescriptor, SortDescriptor } from '@progress/kendo-data-query';
import { BreadcrumbsService } from '../../ui/breadcrumbs/breadcrumbs.service';
import { PageComponent } from '../../ui/page/page.component';
import { ToolbarService } from '../../ui/toolbar/toolbar.service';
import { PersonService } from '../people/person.service';
import { AssistantsService } from './assistants.service';
import { DataStateChangeEvent, GridComponent } from '@progress/kendo-angular-grid';
import { environment } from 'src/environments/environment';
import { Person } from '../people/person';
import { StatePersistingService } from 'src/app/ui/grid/state-persisting.service';
import { AppToolbarComponent, ExportCallback } from 'src/app/ui/toolbar/toolbar.component';
import { finalize, tap } from 'rxjs';

@Component({
  selector: 'app-assistants',
  templateUrl: './assistants.component.html',
  styleUrls: ['./assistants.component.scss']
})
export class AssistantsComponent extends PageComponent implements OnInit, AfterViewInit {
  @ViewChild('grid') grid!: GridComponent;
  @ViewChild('toolbar') toolbar!: AppToolbarComponent;

  public columns: any[] = [
    { field: 'lastname', title: 'Lastname' },
    { field: 'firstname', title: 'Firstname' },
    { field: 'primary_email', title: 'Email' },
    { field: 'pro_phone', title: 'Téléphone bureau' },
  ];

  public state: any = {};
  public searchValue: string | null = null;
  public defaultFilters: CompositeFilterDescriptor = { logic: 'and', filters: [] };
  public defaultSorts: any = [{ dir: 'asc', field: 'lastname' }];


  public localStorageKey: string | null = 'state-grid-assistants';


  constructor(
    public service: PersonService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private assistantsService: AssistantsService,
    public statePersistingService: StatePersistingService
  ) {
    super();
  }

  ngAfterViewInit(): void {
    super.ngAfterViewInit();
    const savedState: any = this.localStorageKey ? this.statePersistingService.get(this.localStorageKey) : null;

    if (savedState) {
      this.state = savedState.state;
      this.searchValue = savedState.searchValue;
    } else {
      this.state.filter = this.defaultFilters;
      this.state.sort = this.defaultSorts;
    }
  }

  ngOnDestroy(): void {
    if (this.localStorageKey) {
      this.statePersistingService.set(this.localStorageKey, { state: this.state, searchValue: this.searchValue });
    }
  }

  public loading: boolean = true;
  public dataResult = [];
  public clickedRowItem?: Person;


  ngOnInit(): void {
    super.ngOnInit();

    this.loading = true;
    this.assistantsService.all().then((data: any) => {
      this.dataResult = data.data;
      this.loading = false;
    });
  }

  public onEdit(person: any): void {
    this.onDataStateChange(this.state);

    if (person.isMember) {
      this.router.navigateByUrl('/admin/members/' + person.isMember);
    } else {
      this.router.navigate([person.id], { relativeTo: this.activatedRoute });
    }
  }

  // Search
  // ------
  public search(inputValue: string | null): void {
    this.loading = true;

    this.searchValue = inputValue;

    let columnFilters: CompositeFilterDescriptor = JSON.parse(JSON.stringify(this.defaultFilters));
    const columnFilterLogic = 'or';
    let fieldFilters = this.columns.map((c: any) => {
      return {
        field: c.field,
        operator: 'contains',
        value: inputValue,
        ignoreCase: true,
        queries: c.queries ?? null,
      };
    });
    let columnFilter: CompositeFilterDescriptor = {
      logic: columnFilterLogic,
      filters: fieldFilters,
    };
    columnFilters.filters.push(columnFilter);

    if (inputValue?.length == 0) {
      this.state.filter = this.defaultFilters;
    } else {
      this.state.filter = columnFilters;
    }

    this.onDataStateChange(this.state);

    this.loading = false;
  }

  // Export
  // ------
  public onExport(callback: ExportCallback) {
    const data: any = this.grid?.data;
    this.assistantsService.downloadFile('cesew/assistants/export',{ ids: data.data.map((e: Person) => e.id) })
      .pipe(finalize(callback))
      .subscribe();
  }

  public onDataStateChange(eventState: DataStateChangeEvent){
    this.state = eventState
    if (this.localStorageKey) {
      this.statePersistingService.set(this.localStorageKey, { state: this.state, searchValue: this.searchValue });
    }
  }
}
