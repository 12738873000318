import { Component, Input, OnInit } from '@angular/core';
import { fromDotNotation } from 'src/app/core/tools';
import { BaseCell } from '../base-cell';

@Component({
  selector: 'app-icon-cell',
  templateUrl: './icon-cell.component.html',
  styleUrls: ['./icon-cell.component.scss']
})
export class IconCellComponent extends BaseCell {


}

export class IconCellData {
  public defaultState?: string;
  public activeState?: string;

  constructor(element?: Partial<IconCellData>) {
    Object.assign(this, element);
  }
}