import { TranslatedString } from "../../../core/TranslatedString";

export class AppointmentStatus {
    id?: number | string | undefined;
    code?: string;
    displayName?: TranslatedString;
    color?: string;
    created_at?: Date;
    updated_at?: Date;
    deleted_a?: Date;

    constructor(object: Partial<AppointmentStatus>) {
        Object.assign(this, object);
    }
}

export enum AppointmentStatusCode {
    "New" = 'New',
    "Convene" = 'Convene',
    "In progress" = 'In progress',
    "Completed" = "Completed"
}

export enum AppointmentStatusBackgroundColor {
    "Default" = 'transparent',
    "New" = '#000000',
    "Convene" = '#b32831',
    "In progress" = '#198754',
    "Completed" = "#555555"
}

export enum AppointmentStatusColor {
    "Default" = 'black',
    "New" = 'white',
    "Convene" = 'white',
    "In progress" = 'white',
    "Completed" = "white"
}
