import { Component, ContentChild, EventEmitter, Input, OnInit, Output, TemplateRef } from '@angular/core';
import { ModalContentDirective } from './modal-content.directive';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent implements OnInit {
  @Input() opened: boolean = false;
  @Input() title: string = "Warning";
  @Input() content: string = "Do you want to continue?";
  @Input() cancelText: any = "Cancel";
  @Input() confirmText: any = "Validate";
  @Input() data: any = {};
  @Input() test:any;
  @Input() width:string | number = 450;
  @Input() showDialogActions: boolean = true;

  @ContentChild(ModalContentDirective, { read: TemplateRef }) modalContentTemplate: any;

  @Output() confirm = new EventEmitter<any>();

  constructor() { }

  ngOnInit(): void {
  }

  onConfirmButtonClicked() {
    this.confirm.emit(this.data);
  }

  public toggle() {
    this.opened = !this.opened;
  }
}
