import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { CarMileageExpenses } from './car-mileage-expenses.model';
import { AppGridComponent } from 'src/app/ui/grid/grid.component';
import { CarMileageExpensesService } from './car-mileage-expenses.service';
import { ToolbarAction } from 'src/app/ui/toolbar/ToolbarAction';
import { ModalComponent } from 'src/app/ui/modal/modal.component';
import { AppNotificationService } from 'src/app/app-notification.service';
import { log } from 'console';

@Component({
  selector: 'app-car-mileage-expenses',
  templateUrl: './car-mileage-expenses.component.html',
  styleUrls: ['./car-mileage-expenses.component.scss']
})
export class CarMileageExpensesComponent implements AfterViewInit {

  public CarMileageExpenses: CarMileageExpenses[] = [];

  @ViewChild('grid') grid?: AppGridComponent;
  @ViewChild('modal') modal?: ModalComponent;

  public columns: any[] = [
    { field: 'amount', title: 'Amount (€/km)' },
    { field: 'startDate', title: 'Start date' },
    { field: 'endDate', title: 'End date' },
  ];

  public toolbarActions = [
    new ToolbarAction({ code: 'Add', text: 'Add', icon: 'plus', click: () => this.onAdd(), btnStyle: 'outline-primary' })
  ];

  constructor(
    public service: CarMileageExpensesService,
    private notificationService: AppNotificationService,
  ) { }

  ngAfterViewInit(): void {
    this.grid?.updateGridDatas();
  }

  public async save(): Promise<void> {
    // console.log("Save");    
  }

  public onConfirmModal(data: { event: 'add' | 'update', amount: number, startDate: Date, endDate: Date, id?: number }) {
    // Common verification
    if (data.event != 'add' && data.event != 'update') {
      this.notificationService.showNotification('Event is not include', 'error');
      return;
    }

    if ((!data.startDate || data.amount < 0)) {
      this.notificationService.showNotification('Start date and amount cannot be empty', 'error');
      return;
    }
    if (data.endDate && data.endDate < data.startDate) {
      this.notificationService.showNotification('End date must be after start date', 'error');
      return;
    }
    if (this.grid?.dataResult?.data && this.isPeriodNotInList(data, this.grid.dataResult.data) == false) {
      this.notificationService.showNotification('This period is already in use', 'error');
      return;
    }

    data.startDate.setHours(12);
    data.endDate?.setHours(12);

    // Save
    if (data.event == 'add') {
      this.service.create(data);
      this.notificationService.showNotification('Created successfull', 'success');
    }
    // update
    if (data.event == 'update') {
      this.service.update(data);
      this.notificationService.showNotification('Update successfull', 'success');
    }
    this.modal?.toggle();
    setTimeout(() => {
      this.grid?.updateGridDatas();
    }, 400);
  }

  private isPeriodNotInList(period: { startDate: Date, endDate: Date, id?: number }, periodList: { startDate: Date, endDate: Date, id: number }[]) {
    const periodStartDate = (new Date(JSON.parse(JSON.stringify(period.startDate)))).setHours(0, 0, 0, 0);
    const periodEndDate = period.endDate ? (new Date(JSON.parse(JSON.stringify(period.endDate)))).setHours(23, 59, 59, 0) : (new Date(JSON.parse(JSON.stringify(period.startDate)))).setHours(23, 59, 59, 0);

    return !periodList.some(item => {
      const itemStartDate = (new Date(item.startDate)).setHours(0, 0, 0, 0);
      const itemEndDate = item.endDate ? new Date(item.endDate).setHours(23, 59, 59, 0) : (new Date(item.startDate)).setHours(23, 59, 59, 0);
      if (period.id && item.id == period.id) return false;

      return ((periodStartDate >= itemStartDate && periodStartDate <= itemEndDate) ||
        (periodEndDate >= itemStartDate && periodEndDate <= itemEndDate) ||
        (periodStartDate <= itemStartDate && periodEndDate >= itemEndDate));
    });
  }


  public onAdd() {
    if (!this.modal) return;
    this.modal.data = {
      event: 'add',
      amount: 0,
      startDate: new Date(),
      endDate: null,
    };

    this.modal.toggle();
  }

  public onEdit(e: CarMileageExpenses) {
    if (!this.modal) return;
    this.modal.data = {
      event: 'update',
      amount: parseFloat((e.amount ?? 0).toString()),
      startDate: e.startDate ? new Date(e.startDate) : null,
      endDate: e.endDate ? new Date(e.endDate) : null,
      id: e.id
    };

    this.modal.toggle();
  }

  public onDelete(e: CarMileageExpenses) {
    if (!e.id) return;
    this.service.delete(e.id);
    this.grid?.deleteModal.toggle();
    this.notificationService.showNotification('Record Deleted', 'success');
    setTimeout(() => {
      this.grid?.updateGridDatas();
    }, 200);
  }
}
