import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppApiService } from 'src/app/app-api.service';
import { AppNotificationService } from 'src/app/app-notification.service';

@Injectable({
  providedIn: 'root'
})
export class FilesService extends AppApiService {
  public url: string | null = 'cesew/upload';

  constructor(
    protected http: HttpClient,
    protected notificationService: AppNotificationService,
    
  ) {
    super(http, notificationService);
  }

  upload(file: any, path: string, filename: string = "", functionName: string = "") {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('path', path);
    formData.append('filename', filename);
    formData.append('allowed_extensions', 'csv');
    formData.append('functionName', functionName);
    return this.post(formData,this.url ?? "cesew/upload");
  }
}
