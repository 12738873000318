import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { map, Observable } from 'rxjs';
import { AppNotificationService } from 'src/app/app-notification.service';
import { AppTranslationsService } from 'src/app/app-translations.service';
import { AuthenticationService } from 'src/app/auth/authentication.service';
import { KendoComponentHelper } from 'src/app/ui/kendo-component-helper/kendo-component-helper';
import { ModalComponent } from 'src/app/ui/modal/modal.component';
import { PageComponent } from 'src/app/ui/page/page.component';
import { ToolbarService } from 'src/app/ui/toolbar/toolbar.service';
import { BreadcrumbsService } from 'src/app/ui/breadcrumbs/breadcrumbs.service';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent extends PageComponent implements OnInit {
  public translations: any[] = [];

  @ViewChild("addTranslationModal") addTranslationModal: ModalComponent = new ModalComponent();
  @ViewChild("deleteTranslationModal") deleteTranslationModal: ModalComponent = new ModalComponent();
  @ViewChild('editTranslationModal') editTranslationModal: ModalComponent = new ModalComponent();
  public translationsHelper: KendoComponentHelper = new KendoComponentHelper();


  public selectedTranslation: any;

  constructor(
    public translationService: AppTranslationsService,
    public notificationService: AppNotificationService,
    public translateService: TranslateService,
    public authenticationService: AuthenticationService,
    public router: Router,
  ) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.translationsHelper.service = this.translationService;
    this.translationsHelper.updateGridDatas();
  }

  // Edit Translation
  // ----------------
  onTranslationTranslated(translation: any) {
    if (translation.id) {
      this.translationService.update(translation).then(r => {
        this.notificationService.showNotification('RecordUpdated', 'success');
        this.translateService.reloadLang(this.translationService.currentLanguage);
        window.location.reload();
      });
    }
    else {
      this.translationService.create(translation).then(r => {
        this.notificationService.showNotification('RecordCreated', 'success');
        this.translateService.reloadLang(this.translationService.currentLanguage);
        window.location.reload();
      });
    }
  }

  onEditTranslationButtonClicked(dataItem: any) {
    this.editTranslationModal!.data = dataItem.value;
    this.editTranslationModal!.data.id = dataItem.id;

    this.editTranslationModal!.toggle();
  }

  onEditTranslationsConfirmed() {
    this.translationService.update(this.translationsHelper.dataResult?.data.find((e: any) => e.id == this.editTranslationModal.data.id)).then(r => {
      this.translationService.all().then((r: any) => {
        this.translations = r.data;
        this.editTranslationModal.toggle();
        this.notificationService.showNotification('RecordCreated', 'success');
        this.translateService.reloadLang(this.translationService.currentLanguage);
        window.location.reload();
      }).finally(() => { this.editTranslationModal.toggle(); });
    }).finally(() => { this.editTranslationModal.toggle(); });
  }

  // Add Translation
  // ---------------
  onAddTranslation() {
    const currentLang = this.authenticationService.user?.preferredLanguage;
    this.addTranslationModal.data = {};
    this.addTranslationModal.data.key = null;
    this.addTranslationModal.data.value = {};
    if (this.toolbarService.searchValue) {

      this.addTranslationModal.data.key = this.toolbarService.searchValue;
      this.addTranslationModal.data.value = {
        en: this.toolbarService.searchValue,
        fr: this.toolbarService.searchValue,
      }
    }

    this.addTranslationModal.toggle();
  }

  onConfirmAddTranslationButtonClicked() {
    const translation = this.addTranslationModal.data;
    this.translationService.create(translation).then(r => {
      this.translationService.all().then((r: any) => {
        this.translations = r.data;
        this.addTranslationModal.toggle();
        this.notificationService.showNotification('RecordCreated', 'success');
        this.translateService.reloadLang(this.translationService.currentLanguage);
        window.location.reload();
      }).finally(() => { this.addTranslationModal.toggle(); });
    }).finally(() => { this.addTranslationModal.toggle(); });
  }


  public valueNormalizer = (text: Observable<string>) =>
    text.pipe(
      map((content: string) => {
        return {
          id: null,
          value: { en: content },
          key: content,
        };
      })
    );

  // Delete Translation
  // ------------------
  onDeleteTranslationButtonClicked(dataItem: any) {
    this.deleteTranslationModal.data = dataItem;
    this.deleteTranslationModal.toggle();
  }

  onConfirmDeleteTranslationButtonClicked() {
    // this.translationsHelper.datas?.find((e: any) => e.id == this.deleteTranslationModal.data.id);

    this.translationService.delete(this.translationsHelper.datas?.find((e: any) => e.id == this.deleteTranslationModal.data.id)).then(r => {
      this.translationService.all().then((r: any) => {
        this.translations = r.data;
        this.deleteTranslationModal.toggle();
        this.notificationService.showNotification('RecordDeleted', 'success');
        this.translateService.reloadLang(this.translationService.currentLanguage);
        window.location.reload();
      }).finally(() => { this.deleteTranslationModal.toggle(); });
    }).finally(() => { this.deleteTranslationModal.toggle(); });
  }

}
