import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EventsService {
  private subjects: any = {};

  constructor() { }

  public emit(eventName: string, data?: any) {
    if (!this.subjects[eventName]) {
      this.subjects[eventName] = new Subject<any>();
    }
    this.subjects[eventName].next(data);
  }

  public on(eventName: string): Observable<any> {
    if (!this.subjects[eventName]) {
      this.subjects[eventName] = new Subject<any>();
    }
    return this.subjects[eventName].asObservable();
  }
}