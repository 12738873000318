import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ModalConfirmComponent } from 'src/app/ui/modal-confirm/modal-confirm.component';

@Component({
  selector: 'app-relation-card',
  templateUrl: './relation-card.component.html',
  styleUrls: ['./relation-card.component.scss']
})
export class RelationCardComponent implements OnInit {
  public showMenu = false;

  @Input() relation: any;
  @Output() relationChange: EventEmitter<any> = new EventEmitter<any>();

  @Output() delete: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('deleteModal') deleteModal: ModalConfirmComponent =
    new ModalConfirmComponent();

  public visibleContact: any = {
    "email": null,
    "phone": null,
  }

  constructor(
    private router: Router
  ) {
  }

  ngOnInit(): void {
    // this.visibleContact["email"] = this.relation.relation.persons_contacts.find((e: any) => e.contact.contact_type?.code == "professional" && e.contact_label?.code === "email");
    // this.visibleContact["phone"] = this.relation.relation.persons_contacts.find((e: any) => e.contact.contact_type?.code == "professional" && e.contact_label?.code === "phone");
    // Get only pro mail/phone
    // -----------------------
    for (const contact of this.relation.relation.persons_contacts) {
      if (contact.contact.contact_type.code === "professional") {
        if (contact.contact.contact_label.code === "email") {
          this.visibleContact["email"] = contact;
        }
        if (contact.contact.contact_label.code === "phone") {
          this.visibleContact["phone"] = contact;
        }
      }
    }
  }

  onEditRelationButtonClicked() {
    this.router.navigateByUrl('/admin/communications/'+this.relation.relation.id);
  }

  onDeleteRelationButtonClicked() {
    this.deleteModal.toggle();
  }

  deleteRelation() {
    this.deleteModal.toggle();
    this.delete.emit(this.relation);
  }
}
