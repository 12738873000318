import { Component, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ArrowStepperItem } from 'src/app/ui/arrow-stepper-item/arrow-stepper-item.model';
import { PageComponent } from 'src/app/ui/page/page.component';
import { AppointmentStatus } from '../appointment-statuses/appointment-status';
import { AppointmentStatusesService } from '../appointment-statuses/appointment-statuses.service';
import { Appointment } from '../appointment';
import { ToolbarAction } from 'src/app/ui/toolbar/ToolbarAction';
import { BreadcrumbEvents } from 'src/app/ui/breadcrumbs/breadcrumb-events';
import { AppointmentService } from '../appointment.service';
import { AppNotificationService } from 'src/app/app-notification.service';
import { Committee } from '../../committees/committee.modele';
import { CommitteesService } from '../../committees/committees.service';
import { AppointmentAttendee } from '../appointment-attendees/appointment-attendee';
import { AppointmentsCommittees } from '../appointments-committees/appointments-committees';
import { AppointmentsCommitteesService } from '../appointments-committees/appointments-committees.service';
import { AppGridComponent } from 'src/app/ui/grid/grid.component';
import { KendoComponentHelper } from 'src/app/ui/kendo-component-helper/kendo-component-helper';
import { ModalComponent } from 'src/app/ui/modal/modal.component';
import { PersonService } from '../../people/person.service';
import { AppointmentAttendeesService } from '../appointment-attendees/appointment-attendees.service';
import { AppointmentAttendeeResponsesService } from '../appointment-attendee-responses/appointment-attendee-responses.service';
import { AppointmentAttendeeResponse } from '../appointment-attendee-responses/appointment-attendee-response';
import { TenuresService } from '../../companies/tenures/tenures.service';

@Component({
  selector: 'app-appointment-details',
  templateUrl: './appointment-details.component.html',
  styleUrls: ['./appointment-details.component.scss']
})
export class AppointmentDetailsComponent extends PageComponent {
  public element?: Appointment;
  public appointmentStatuses: AppointmentStatus[] = [];
  public appointmentAttendeeResponses: AppointmentAttendeeResponse[] = [];
  public statuses: ArrowStepperItem[] = [];
  public committees: Committee[] = [];
  public selectedCommittees: Committee[] = [];
  public originalCommittees: Committee[] = [];

  public duration: number = 0;

  public columns: any[] = [
    {
      field: 'person.lastname', title: 'Lastname', joins: [{
        sequence: 1, tablename: "appointment_attendees", foreignKey: "appointment_attendees.person_id"
      }]
    },
    {
      field: 'person.firstname', title: 'Firstname', joins: [{
        sequence: 1, tablename: "appointment_attendees", foreignKey: "appointment_attendees.person_id"
      }],
    },
    {
      field: 'person.person_tenure.displayName.fr', title: 'Tenure', filter: 'multi', service: this.tenuresService, joins: [
        { sequence: 1, tablename: "appointment_attendees", foreignKey: "appointment_attendees.person_id" },
        { sequence: 2, tablename: "person_tenures", foreignKey: "people.tenure_id" }
      ]
    },
    {
      field: 'isTeams', title: 'Teams', filter: 'boolean', type: "boolean", width: 150
    },
    {
      field: 'isFaceToFace', title: 'Présentiel', filter: 'boolean', type: "boolean", width: 150
    }
  ];

  public baseSorts = [{
    dir: 'asc', field: 'person.lastname', joins: [
      { sequence: 1, tablename: 'people', foreignKey: 'members.person_id' }
    ]
  }];

  @ViewChild('attendeesGrid') attendeesGrid?: AppGridComponent;
  @ViewChild('editAppointmentAttendeerModal') public editAppointmentAttendeerModal: ModalComponent = new ModalComponent();
  @ViewChild('addAttendeeModal') addAttendeeModal: ModalComponent = new ModalComponent();
  @ViewChild('appointmentDeleteModal') private appointmentDeleteModal: ModalComponent = new ModalComponent();
  @ViewChild('synchroniseSelectModal') public synchroniseSelectModal: ModalComponent = new ModalComponent();

  public membersHelper: KendoComponentHelper = new KendoComponentHelper();
  public titlesHelper: KendoComponentHelper = new KendoComponentHelper();
  public appointmentAttendeeResponsesHelper: KendoComponentHelper = new KendoComponentHelper();

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private appointmentStatusesService: AppointmentStatusesService,
    private appointmentService: AppointmentService,
    private notificationService: AppNotificationService,
    private committeesService: CommitteesService,
    private appointmentsCommitteesService: AppointmentsCommitteesService,
    private personService: PersonService,
    private appointmentAttendeesService: AppointmentAttendeesService,
    private appointmentAttendeeResponsesService: AppointmentAttendeeResponsesService,
    private tenuresService: TenuresService
  ) {
    super();

    this.route.data.subscribe(({ element }) => {
      this.onElement(element.data);

      this.breadcrumbsService.parser = (segment: string) => {
        return {
          text: this.getTextSegment(segment),
          click: (e: any) => { this.router.navigateByUrl('admin/' + segment) }
        }
      };

      this.eventsService.emit(BreadcrumbEvents.Reload);
    });
  }

  private onElement(element: Appointment): void {
    element.startDate = new Date(element.startDate ?? 0);
    element.endDate = new Date(element.endDate ?? 0);

    this.element = element;
  }

  async ngOnInit(): Promise<void> {
    super.ngOnInit();
    this.notificationService.toggleLoader();

    await this.loadAppointmentStatuses();
    await this.loadAppointmentCommittees();
    await this.loadAppointmentAttendeeResponses();

    this.initActions();
    this.attendeesGrid?.actions.push(new ToolbarAction({ code: 'addAttendee', text: 'Add attendee', permissions:['appointment.add_attendee'], icon: 'plus', click: () => this.onAddAttendeeButtonClicked(), btnStyle: 'outline-primary' }));
    this.notificationService.toggleLoader();

    setTimeout(() => {
      this.attendeesGrid?.updateGridDatas();
    },200);
  }

  private initActions() {
    this.toolbarService.reset();
    this.addAction({ code: 'save', text: this.element?.id === 'new' ? 'Create' : 'Save', icon: 'save', permissions:['appointment.save'], click: () => this.onSaveButtonClicked(), btnStyle: 'outline-primary' });
    this.addAction({ code: 'synchronize', text: 'Synchronize', icon: 'rotate', permissions:['appointment.synchronize'], click: () => this.onSynchronizeButtonClicked(), btnStyle: 'outline-primary' });
    this.addAction({ code: 'delete', text: 'Delete', icon: 'trash', btnStyle: "dark", permissions:['appointment.delete'], click: () => this.onDeleteButtonClicked(), atEnd: true });
    this.addAction({ code: 'facetoface', btnClass: "ms-5", icon: 'building', permissions:['appointment.facetoface'], iconClass: this.element?.isFaceToFace ? 'text-primary' : 'text-dark', click: () => this.onFaceToFaceButtonClicked(), atEnd: true });
    this.addAction({ code: 'separator', permissions:['appointment.teams'], text: "/", atEnd: true });
    this.addAction({ code: 'teams', permissions:['appointment.teams'], image: this.element?.isTeams ? '/assets/teams-colored.svg' : '/assets/teams-default.svg', imageStyle: { height: "20px" }, click: () => this.onTeamsButtonClicked(), btnStyle: 'outline', atEnd: true });
  }

  private async loadAppointmentStatuses() {
    this.appointmentStatuses = (await this.appointmentStatusesService.all())?.data || [];
    this.statuses = this.appointmentStatuses.map((e: AppointmentStatus) => {
      return new ArrowStepperItem({
        data: e,
        active: this.element?.appointment_status_id == e.id,
        bgColor: e.color,
        canHover: false
      }) || [];
    });
  }

  private async loadAppointmentCommittees() {
    this.committees = (await this.committeesService.all())?.data || [];
    if (!this.committees) return;

    this.committees = this.committees.sort((a, b) => (a.code || '').toLowerCase().localeCompare((b.code || '').toLowerCase()));

    this.selectedCommittees = this.originalCommittees = this.committees.filter((c: Committee) => {
      return this.element?.appointment_committees?.map((link: AppointmentsCommittees) => link.committee?.id).includes(c.id);
    });
  }

  private async loadAppointmentAttendeeResponses(): Promise<void> {
    this.appointmentAttendeeResponses = (await this.appointmentAttendeeResponsesService.all())?.data || [];
    this.appointmentAttendeeResponsesHelper.datas = this.appointmentAttendeeResponses;
  }

  // Delete
  // ------
  public async onDeleteButtonClicked() {
    this.appointmentDeleteModal.toggle();
  }

  public async onConfirmDelete() {
    this.notificationService.toggleLoader();
    await this.appointmentService.delete(this.element?.id);
    this.router.navigateByUrl('admin/appointments');
    this.notificationService.toggleLoader();
  }

  // Breadcrumb
  // ----------
  public getTextSegment(segment: string) {
    if (!isNaN(+segment)) {
      return this.element?.subject;
    }
    return segment.charAt(0).toUpperCase() + segment.slice(1);
  }

  // Attendees
  // ---------
  public async onPresenceChange(event: { field: string, dataItem: AppointmentAttendee }) {
    if (event.field !== 'present' && event.field !== 'isTeams' && event.field !== 'isFaceToFace') return;

    event.dataItem[event.field] = !event.dataItem[event.field];
    await this.appointmentAttendeesService.updateAssoc({
      'old': {
        appointment_id: event.dataItem.appointment_id,
        person_id: event.dataItem.person_id
      },
      'new': {
        present: event.dataItem.present,
        isTeams: event.dataItem.isTeams,
        isFaceToFace: event.dataItem.isFaceToFace
      }
    });
  }

  public onEditAppointmentAttendee(event: any): void {
    this.editAppointmentAttendeerModal.data = event;
    this.editAppointmentAttendeerModal.toggle();
  }

  public async onConfirmEditAppointmentAttendeeBtn(): Promise<void> {
    if (!this.element) return;

    this.attendeesGrid?.updateGridDatas();
    this.notificationService.toggleLoader();
    try {
      const appointmentAttendeeResponse = await this.appointmentAttendeeResponsesService.updateResponse(
        this.editAppointmentAttendeerModal.data.person_id,
        this.editAppointmentAttendeerModal.data.appointment_id,
        this.editAppointmentAttendeerModal.data.appointment_attendee_response_id
      );

      const updatedAttendee = this.element?.appointment_attendees?.find(a => a.person_id === appointmentAttendeeResponse.person_id);
      if (updatedAttendee) {
        updatedAttendee.appointment_attendee_response = appointmentAttendeeResponse.appointment_attendee_response;
        updatedAttendee.appointment_attendee_response_id = appointmentAttendeeResponse.appointment_attendee_response.id;
      }


      const tmp = JSON.parse(JSON.stringify(this.element.appointment_attendees));
      delete this.element.appointment_attendees;
      this.element.appointment_attendees = tmp;

    } catch (error) { }
    this.editAppointmentAttendeerModal.toggle();
    this.notificationService.toggleLoader();
  }

  // Teams Tab
  // ---------
  public onJoinTeamsUrl(url?: string) {
    window.open(url, '_blank');
  }

  public copyTeamsUrl(url?: string) {
    if (!url) return;
    navigator.clipboard.writeText(url);
  }
  // ------------------------------------------------------------------------------

  public async onSaveButtonClicked() {
    var response = await this.appointmentService.createOrUpdate({...this.element, startDate : undefined, endDate: undefined});
    this.selectedCommittees?.forEach((c: Committee) => {
      if (!this.originalCommittees.some(original => original.id === c.id)) {
        var link = new AppointmentsCommittees({
          appointment_id: response.data?.id,
          committee_id: c.id,
        });
        this.appointmentsCommitteesService.create(link);
      }
    });

    this.notificationService.showNotification('Record Updated', 'success');
  }

  public async onSynchronizeButtonClicked() {
    this.synchroniseSelectModal.data.syncStatus = true;
    if (this.element?.isTeams) {
      this.synchroniseSelectModal.data.syncAttendance = true;
    }
    this.synchroniseSelectModal.toggle();
  }

  public async onConfirmSynchronizeModal(data: any) {


    if (!this.element) return;
    this.notificationService.toggleLoader('Synchronisation in progress...');

    try {
      const element = await this.appointmentService.synchronizeAppointment(this.element.id, data);
      this.onElement(element);

      await this.loadAppointmentStatuses();
      this.initActions();
      this.attendeesGrid?.updateGridDatas();

      this.breadcrumbsService.updateBreadcrumbs();
      this.synchroniseSelectModal.toggle();
    } catch (error) { }

    this.notificationService.toggleLoader('Synchronisation in progress...');
  }

  public onFaceToFaceButtonClicked() {
    if (!this.element) return;
    this.element.isFaceToFace = !this.element?.isFaceToFace;
    this.onIsFaceToFaceValueChange(this.element.isFaceToFace);
  }

  public onTeamsButtonClicked() {
    if (!this.element) return;
    this.element.isTeams = !this.element?.isTeams;
    this.onIsTeamsValueChange(this.element.isTeams);
  }

  public onIsFaceToFaceValueChange(value: boolean) {
    this.toolbarService.actions.find((e: ToolbarAction) => e.code == 'facetoface').iconClass = value ? 'text-primary' : 'text-dark';
  };
  public onIsTeamsValueChange(value: boolean) {
    this.toolbarService.actions.find((e: ToolbarAction) => e.code == 'teams').image = value ? '/assets/teams-colored.svg' : '/assets/teams-default.svg'
  };

  // Add attendees
  // -------------
  public async onAddAttendeeButtonClicked() {
    this.addAttendeeModal.data = {
      primary_email: ''
    }
    this.addAttendeeModal.toggle();

  }

  public async onConfirmAddAttendeeBtn() {
    if (!this.addAttendeeModal.data.id) return; // Block person creation for now

    this.notificationService.toggleLoader();

    await this.appointmentAttendeesService.create({
      appointment_id: this.element?.id,
      person_id: this.addAttendeeModal.data.id,
      appointment_attendee_role_id: 1,
      appointment_attendee_response_id: 1,
      isTeams: 1,
      isFaceToFace: 1,
      present: 0
    });

    this.notificationService.toggleLoader();
    this.addAttendeeModal.toggle();

    window.location.reload(); // Must reload attendees grid. Same issue #4595
  }

  // Search
  // ------
  public emails: Array<any> = [];
  public isSearching: boolean = false;
  public hasSearchForEmail: boolean = false;
  public personAlreadyExist: boolean = false;
  public shouldShowDropDown: boolean = false;

  public async onSearchEmailClicked(): Promise<void> {
    this.isSearching = true;
    this.personAlreadyExist = false;
    this.hasSearchForEmail = false;
    this.shouldShowDropDown = false;

    const result: any = await this.personService.all({ filter: { filters: [{ field: 'primary_email', operator: 'contains', value: this.addAttendeeModal?.data.primary_email, ignoreCase: true }], logic: 'and' } });

    if (result.data.length === 0) {
      this.hasSearchForEmail = true;
    } else if (result.data.length > 1) {
      this.emails = result.data;
      this.shouldShowDropDown = true;
    } else if (result.data.length === 1) {
      this.onPersonFound(result.data[0]);
    }

    this.isSearching = false;
  }

  public onPersonFound(person: any) {
    if (!this.addAttendeeModal) return;
    this.addAttendeeModal.data.id = person.id;
    this.addAttendeeModal.data.firstname = person.firstname;
    this.addAttendeeModal.data.lastname = person.lastname;
    this.addAttendeeModal.data.primary_email = person.primary_email;
    this.addAttendeeModal.data.title = person.person_title;

    this.personAlreadyExist = true;
    this.hasSearchForEmail = true;
    this.shouldShowDropDown = false;
  }

  public onKeyDown(pressedKey: any) {
    if (pressedKey.key === "Enter") {
      this.onSearchEmailClicked();
    }
  }

  // Create
  // ------
  public onAddRelationButtonClicked() {
    if (!this.addAttendeeModal) return;

    this.addAttendeeModal.data = {
      firstname: '',
      lastname: '',
      primary_email: '',
      id: null,
      relation_type: { id: 1 }
    };
    this.hasSearchForEmail = false;
    this.shouldShowDropDown = false;
    this.addAttendeeModal?.toggle();
  }

  public async onConfirmAdd(): Promise<void> {
    if (!this.addAttendeeModal) return;

    this.addAttendeeModal.data.title_id = this.addAttendeeModal?.data.title.id;
    if (!this.personAlreadyExist && !await this.personService.canCreatePerson(this.addAttendeeModal?.data, true)) return;

    this.addAttendeeModal?.toggle();

    // if (this.onCreate.observed) return this.onCreate.emit(this.addAttendeeModal?.data);

    this.notificationService.showNotification('Record Created', 'success');
  }

  // Export
  // ------
  public onAttendeeExport() {
    if (!this.element?.id) return;
    this.appointmentService.exportAttendees(this.element.id).subscribe();
  }
}
