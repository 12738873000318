<app-toolbar
  #toolbar
  [actions]="actions"
  [searchable]="searchable"
  [exportable]="exportable"
  (search)="search($event)"
  (export)="onExport($event)"
></app-toolbar>

<kendo-grid
  #grid="kendoGrid"
  [loading]="loading"
  [data]="dataResult"
  [selectable]="selectable"
  [(selectedKeys)]="selectedElements"
  [kendoGridSelectBy]="selectedBy"
  [pageSize]="state.take"
  [skip]="state.skip"
  [pageable]="pageable"
  [sortable]="sortable"
  [sort]="state.sort"
  [filter]="state.filter"
  [filterable]="filterable ? 'menu' : false"
  (dataStateChange)="dataStateChange($event)"
  (selectedKeysChange)="selectedElementsChange.emit($event)"
  (cellClick)="onCellClick($event)"
  (dblclick)="onDblClick($event)"
>
  <kendo-grid-checkbox-column
    *ngIf="selectable"
    [showSelectAll]="true"
    [width]="40"
  ></kendo-grid-checkbox-column>

  <kendo-grid-column
    *ngFor="let col of columns"
    [field]="col.field"
    [filter]="col.filter"
    [width]="col.width"
    title=""
  >
    <ng-template
      kendoGridFilterMenuTemplate
      let-filter="filter"
      let-column="column"
      let-filterService="filterService"
    >
      <kendo-grid-string-filter-menu
        *ngIf="!col.filter"
        [column]="column"
        [filter]="filter"
        [filterService]="filterService"
        [extra]="false"
      ></kendo-grid-string-filter-menu>

      <kendo-grid-boolean-filter-menu
        *ngIf="col.filter == 'boolean'"
        [column]="column"
        [filter]="filter"
        [filterService]="filterService"
      ></kendo-grid-boolean-filter-menu>

      <kendo-grid-date-filter-menu
        *ngIf="col.filter == 'date'"
        [column]="column"
        [filter]="filter"
        [filterService]="filterService"
        [extra]="false"
      ></kendo-grid-date-filter-menu>

      <app-multicheck-filter
        *ngIf="col.filter == 'multi'"
        [currentFilter]="filter"
        [field]="col.field"
        [filterService]="filterService"
        [service]="col.service"
      ></app-multicheck-filter>
    </ng-template>
    <ng-template
      kendoGridHeaderTemplate
      let-column
      let-columnIndex="columnIndex"
    >
      <ng-container *ngIf="col.title">{{ col.title | translate }}</ng-container>
    </ng-template>

    <ng-template kendoGridCellTemplate let-dataItem="dataItem">
      <div [ngSwitch]="col.type">
        <div *ngSwitchCase="'status'">
          <app-status-cell [column]="col" [dataItem]="dataItem"></app-status-cell>
        </div>
        <div *ngSwitchCase="'icon'">
          <app-icon-cell [column]="col" [dataItem]="dataItem"></app-icon-cell>
        </div>
        <div *ngSwitchCase="'image'">
          <app-image-cell [column]="col" [dataItem]="dataItem"></app-image-cell>
        </div>
        <div *ngSwitchCase="'boolean'">
          <app-boolean-cell [column]="col" [dataItem]="dataItem" (valueChange)="onBooleanClick.emit({ field: col.field, dataItem: dataItem })"></app-boolean-cell>
        </div>
        <div *ngSwitchCase="'date'">
          <app-date-cell [column]="col" [dataItem]="dataItem"></app-date-cell>
        </div>
        <div *ngSwitchCase="'appointment_committees'">
          <app-appointment-committees-cell [column]="col" [dataItem]="dataItem"></app-appointment-committees-cell>
        </div>
        <div *ngSwitchDefault>{{ fromDotNotation(dataItem, col.field) }}</div>
      </div>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column
    *ngIf="editable || deletable"
    title="{{ 'Actions' | translate }}"
    [width]="100"
    headerClass="text-center"
  >
    <ng-template kendoGridCellTemplate let-dataItem>
      <div class="d-flex justify-content-center">
        <i
          *ngIf="editable"
          [hasPermission]="['members.update']"
          [toRemove]="true"
          class="fas fa-pen text-info p-2"
          role="button"
          (click)="onEditButtonClicked(dataItem)"
        ></i>
        <i
          *ngIf="deletable"
          [hasPermission]="['members.update']"
          [toRemove]="true"
          class="fas fa-trash text-danger p-2"
          role="button"
          (click)="onDeleteButtonClicked(dataItem)"
        ></i>
      </div>
    </ng-template>
  </kendo-grid-column>
</kendo-grid>

<app-modal-confirm
  #deleteModal
  content="{{ 'Are you sure to delete' | translate }}"
  (confirm)="onConfirmDelete($event)"
></app-modal-confirm>
