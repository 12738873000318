import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { SncbMileageExpenses } from './sncb-mileage-expenses.model';
import { AppGridComponent } from 'src/app/ui/grid/grid.component';
import { SncbMileageExpensesService } from './sncb-mileage-expenses.service';
import { ToolbarAction } from 'src/app/ui/toolbar/ToolbarAction';
import { ModalComponent } from 'src/app/ui/modal/modal.component';
import { AppNotificationService } from 'src/app/app-notification.service';
import { FileRestrictions } from '@progress/kendo-angular-upload';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { FilesService } from 'src/app/ui/upload-file/file.service';

@Component({
  selector: 'app-sncb-mileage-expenses',
  templateUrl: './sncb-mileage-expenses.component.html',
  styleUrls: ['./sncb-mileage-expenses.component.scss']
})
export class SncbMileageExpensesComponent implements AfterViewInit {

  public SncbMileageExpenses: SncbMileageExpenses[] = [];

  @ViewChild('grid') grid?: AppGridComponent;
  @ViewChild('modal') modal?: ModalComponent;
  @ViewChild('uploadModal') uploadModal?: ModalComponent;

  public columns: any[] = [
    { field: 'mileage', title: 'Mileage' },
    { field: 'amount', title: 'Amount (€)' },
    { field: 'startDate', title: 'Start date' },
    { field: 'endDate', title: 'End date' },
  ];

  public toolbarActions = [
    new ToolbarAction({ code: 'Add', text: 'Add', icon: 'plus', click: () => this.onAdd(), btnStyle: 'outline-primary' }),
    new ToolbarAction({ code: 'Import', text: 'Import', icon: 'file-import', click: () => this.onImport(), btnStyle: 'outline-primary' })
  ];

  constructor(
    public service: SncbMileageExpensesService,
    private notificationService: AppNotificationService,
    private filesService: FilesService,
    private ngxService: NgxUiLoaderService,
  ) { }

  ngAfterViewInit(): void {
    this.grid?.updateGridDatas();
  }

  public async save(): Promise<void> {
    // console.log("Save");    
  }

  public onConfirmModal(data: { event: 'add' | 'update', amount: number, startDate: Date, endDate: Date, id?: number, mileage: number }) {
    // Common verification
    if (data.event != 'add' && data.event != 'update') {
      this.notificationService.showNotification('Event is not include', 'error');
      return;
    }

    if (!data.startDate || data.amount < 0 || data.mileage <= 0) {
      this.notificationService.showNotification('Start date,  and amount cannot be empty', 'error');
      return;
    }
    if (data.endDate && data.endDate < data.startDate) {
      this.notificationService.showNotification('End date must be after start date', 'error');
      return;
    }
    if (this.grid?.dataResult?.data && this.isPeriodNotInList(data, this.grid.dataResult.data) == false) {
      this.notificationService.showNotification('This period is already in use', 'error');
      return;
    }

    data.startDate.setHours(12);
    data.endDate?.setHours(12);

    // Save
    if (data.event == 'add') {
      this.service.create(data);
      this.notificationService.showNotification('Created successfull', 'success');
    }
    // update
    if (data.event == 'update') {
      this.service.update(data);
      this.notificationService.showNotification('Update successfull', 'success');
    }
    this.modal?.toggle();
    setTimeout(() => {
      this.grid?.updateGridDatas();
    }, 400);
  }

  private isPeriodNotInList(period: { startDate: Date, endDate: Date, id?: number, mileage: number }, periodList: { startDate: Date, endDate: Date, id: number, mileage: number }[]) {
    const periodStartDate = (new Date(JSON.parse(JSON.stringify(period.startDate)))).setHours(0, 0, 0, 0);
    const periodEndDate = period.endDate ? (new Date(JSON.parse(JSON.stringify(period.endDate)))).setHours(23, 59, 59, 0) : (new Date(JSON.parse(JSON.stringify(period.startDate)))).setHours(23, 59, 59, 0);

    return !periodList.some(item => {
      const itemStartDate = (new Date(item.startDate)).setHours(0, 0, 0, 0);
      const itemEndDate = item.endDate ? new Date(item.endDate).setHours(23, 59, 59, 0) : (new Date(item.startDate)).setHours(23, 59, 59, 0);
      if (period.id && item.id == period.id) return false;

      return ((periodStartDate >= itemStartDate && periodStartDate <= itemEndDate) ||
        (periodEndDate >= itemStartDate && periodEndDate <= itemEndDate) ||
        (periodStartDate <= itemStartDate && periodEndDate >= itemEndDate)) && item.mileage == period.mileage;
    });
  }


  public onAdd() {
    if (!this.modal) return;
    this.modal.data = {
      event: 'add',
      amount: 0,
      startDate: new Date(),
      endDate: null,
      mileage: 1,
    };

    this.modal.toggle();
  }

  public onEdit(e: SncbMileageExpenses) {
    if (!this.modal) return;
    this.modal.data = {
      event: 'update',
      amount: parseFloat((e.amount ?? 0).toString()),
      mileage: parseFloat((e.mileage ?? 0).toString()),
      startDate: e.startDate ? new Date(e.startDate) : null,
      endDate: e.endDate ? new Date(e.endDate) : null,
      id: e.id
    };

    this.modal.toggle();
  }

  public onDelete(e: SncbMileageExpenses) {
    if (!e.id) return;
    this.service.delete(e.id);
    this.grid?.deleteModal.toggle();
    this.notificationService.showNotification('Record Deleted', 'success');
    setTimeout(() => {
      this.grid?.updateGridDatas();
    }, 200);
  }
  // Import
  // ----------------------------
  public fileToImport: any = undefined;
  private filepath: string = "uploads/files/temp";
  private filename: string = "test";
  public fileRestrictions: FileRestrictions = {
    allowedExtensions: ['.csv']
  };

  public onImport() {
    this.uploadModal?.toggle();
  }

  public onConfirmUploadModal() {
    this.ngxService.startLoader('loader-upload');
    this.filesService.upload(this.fileToImport.files[0].rawFile, this.filepath, this.filename).then((r: any) => {
      this.service.importFile(r.file).then((e: any) => {
        this.notificationService.showNotification(e.result, 'info');
        this.uploadModal?.toggle();
        setTimeout(() => {
          this.grid?.updateGridDatas();
        }, 200);
        this.ngxService.stopLoader('loader-upload');
      })
    });
  }

  selectEventHandler(data: any) {
    this.fileToImport = data;
  }
}
