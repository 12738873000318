import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppApiService } from 'src/app/app-api.service';
import { AppNotificationService } from 'src/app/app-notification.service';

@Injectable({
  providedIn: 'root'
})
export class PersonsRelationTypeService extends AppApiService {
  public loading: boolean = false;
  public url: string | null = 'crm/personRelationTypes';

  constructor(
    protected http: HttpClient,
    protected notificationService?: AppNotificationService
  ) {
    super(http, notificationService);
  }
}
