import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { forkJoin } from 'rxjs';
import { AppNotificationService } from 'src/app/app-notification.service';
import { AuthenticationService } from 'src/app/auth/authentication.service';
import { KendoComponentHelper } from 'src/app/ui/kendo-component-helper/kendo-component-helper';
import { ModalComponent } from 'src/app/ui/modal/modal.component';
import { AddressTypesService } from '../address-types.service';
import { AddressesService } from '../addresses.service';

@Component({ 
  selector: 'app-address-form',
  templateUrl: './address-form.component.html',
  styleUrls: ['./address-form.component.scss']
})
export class AddressFormComponent implements OnInit {
  @Input() public sendLetterToPrivate?: boolean;
  @Output() public sendLetterToPrivateChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Input() public addresses: any[] = [];
  @Output() public addressesChange: EventEmitter<any[]> = new EventEmitter<any[]>();

  @Output() public onCreate: EventEmitter<any> = new EventEmitter<any>();
  @Output() public onDelete: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('addModal') addModal: ModalComponent = new ModalComponent();

  public addressTypesHelper: KendoComponentHelper = new KendoComponentHelper();

  public actions: any[] = [
    {
      code: 'add-address',
      text: 'Add address',
      icon: 'map-marker',
      click: () => this.onAddAddressButtonClicked(),
    },
  ];

  constructor(
    public authenticationService: AuthenticationService,
    public addressTypesService: AddressTypesService,
    public addressesService: AddressesService,
    public notificationService: AppNotificationService,
  ) { }

  ngOnInit(): void {
    forkJoin({
      types: this.addressTypesService.all(),
    }).subscribe((responses: any) => {
      this.addressTypesHelper.datas = this.addressTypesHelper.filteredDatas = responses.types.data;
    });
  }

  // Create
  // ------
  public onAddAddressButtonClicked(): void {
    this.addModal.data = {};
    this.addModal.toggle();
  }

  public async onConfirmAdd(): Promise<void> {
    const address: any = await this.addressesService.create({});

    this.addModal.toggle();

    if (this.onCreate.observed) return this.onCreate.emit({
      address_id: address.data.id,
      address_type_id: this.addModal.data.address_type.id
    });

    this.notificationService.showNotification('Record Created', 'success'); 
  }

  // delete
  // ------
  public onConfirmDelete(address: any): void {
    // No address deletion
    if (this.onDelete.observed) return this.onDelete.emit(address);

    this.notificationService.showNotification('Record Deleted', 'success');
  }
}
